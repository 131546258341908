// import React, { useState } from 'react';

// const Register = () => {
//   const [showAttendeeForm, setShowAttendeeForm] = useState(false);
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     adults: 1,
//     kids: 0,
//   });
//   const [attendees, setAttendees] = useState([]);
//   const [totalPeople, setTotalPeople] = useState(1);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handlePeopleSelection = (e) => {
//     const { name, value } = e.target;
//     const updatedValue = parseInt(value, 10);
//     setFormData({ ...formData, [name]: updatedValue });
//     setTotalPeople(parseInt(formData.adults) + parseInt(formData.kids));
//   };

//   const handleNext = () => {
//     if (formData.adults >= 1 && totalPeople > 0 && totalPeople <= 6) {
//       const people = Array.from({ length: totalPeople }, () => ({
//         name: '',
//         email: '',
//         sex: '',
//         age: '',
//         type: '',
//         price: 100,
//       }));
//       setAttendees(people);
//       setShowAttendeeForm(true); // Display the attendee form
//     }
//   };

//   const handleAttendeeChange = (index, field, value) => {
//     const updatedAttendees = [...attendees];
//     updatedAttendees[index] = { ...updatedAttendees[index], [field]: value };
//     setAttendees(updatedAttendees);
//   };

//   const handleSliderChange = (index, value) => {
//     const updatedAttendees = [...attendees];
//     updatedAttendees[index].price = value;
//     setAttendees(updatedAttendees);
//   };

//   return (
//     <div
//       className="w-11/12 mx-auto bg-gray-50 flex flex-col justify-center items-center p-8"
//       style={{ minHeight: '100vh', overflow: 'auto' }} // Scrollability for the outer container
//     >
//       <div className="w-full md:w-1/2 max-h-[80vh] overflow-y-auto"> {/* Enable scrollability for the form container */}
//         <h2 className="text-3xl font-bold text-gray-900 mb-6">Event Registration</h2>
//         <form className="space-y-4">
//           <div>
//             <label className="block mb-2">Name</label>
//             <input
//               type="text"
//               name="name"
//               value={formData.name}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               required
//             />
//           </div>
//           <div>
//             <label className="block mb-2">Email</label>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               required
//             />
//           </div>
//           <div>
//             <label className="block mb-2">Phone (US only)</label>
//             <input
//               type="tel"
//               name="phone"
//               value={formData.phone}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
//               placeholder="123-456-7890"
//               required
//             />
//           </div>
//           <div>
//             <label className="block mb-2">Adults</label>
//             <input
//               type="number"
//               name="adults"
//               value={formData.adults}
//               onChange={handlePeopleSelection}
//               className="w-full border border-gray-300 rounded p-2"
//               min="1"
//               max="6"
//               required
//             />
//           </div>
//           <div>
//             <label className="block mb-2">Kids</label>
//             <input
//               type="number"
//               name="kids"
//               value={formData.kids}
//               onChange={handlePeopleSelection}
//               className="w-full border border-gray-300 rounded p-2"
//               min="0"
//               max={6 - formData.adults}
//             />
//           </div>
//           <button
//             type="button"
//             onClick={handleNext}
//             className="bg-blue-600 text-white font-bold py-2 px-6 rounded-full hover:bg-blue-700 transition duration-300"
//           >
//             Next
//           </button>
//         </form>
//       </div>

//       {/* Conditional display of attendee form */}
//       {showAttendeeForm && (
//         <div className="w-full md:w-1/2 mt-8 max-h-[80vh] overflow-y-auto"> {/* Scrollability for the attendee form */}
//           <h2 className="text-3xl font-bold text-gray-900 mb-6">Attendee Details</h2>
//           {attendees.map((attendee, index) => (
//             <div key={index} className="space-y-4 mb-6">
//               <h3 className="text-xl font-bold text-gray-700">Enter details for person {index + 1}</h3>
//               <div>
//                 <label className="block mb-2">Name</label>
//                 <input
//                   type="text"
//                   value={attendee.name}
//                   onChange={(e) => handleAttendeeChange(index, 'name', e.target.value)}
//                   className="w-full border border-gray-300 rounded p-2"
//                   required
//                 />
//               </div>
//               <div>
//                 <label className="block mb-2">Email</label>
//                 <input
//                   type="email"
//                   value={attendee.email}
//                   onChange={(e) => handleAttendeeChange(index, 'email', e.target.value)}
//                   className="w-full border border-gray-300 rounded p-2"
//                   required
//                 />
//               </div>
//               <div>
//                 <label className="block mb-2">Sex</label>
//                 <div className="flex items-center space-x-4">
//                   <label>
//                     <input
//                       type="radio"
//                       name={`sex-${index}`}
//                       value="Male"
//                       onChange={(e) => handleAttendeeChange(index, 'sex', e.target.value)}
//                     />
//                     Male
//                   </label>
//                   <label>
//                     <input
//                       type="radio"
//                       name={`sex-${index}`}
//                       value="Female"
//                       onChange={(e) => handleAttendeeChange(index, 'sex', e.target.value)}
//                     />
//                     Female
//                   </label>
//                 </div>
//               </div>
//               <div>
//                 <label className="block mb-2">Age</label>
//                 <input
//                   type="number"
//                   value={attendee.age}
//                   onChange={(e) => handleAttendeeChange(index, 'age', e.target.value)}
//                   className="w-full border border-gray-300 rounded p-2"
//                   required
//                 />
//               </div>
//               <div>
//                 <label className="block mb-2">Are they an Adult or Kid?</label>
//                 <div className="flex items-center space-x-4">
//                   <label>
//                     <input
//                       type="radio"
//                       name={`type-${index}`}
//                       value="Adult"
//                       onChange={(e) => handleAttendeeChange(index, 'type', e.target.value)}
//                       checked={attendee.type === 'Adult'}
//                     />
//                     Adult
//                   </label>
//                   <label>
//                     <input
//                       type="radio"
//                       name={`type-${index}`}
//                       value="Kid"
//                       onChange={(e) => handleAttendeeChange(index, 'type', e.target.value)}
//                     />
//                     Kid
//                   </label>
//                 </div>
//               </div>
//               <div>
//                 <label className="block mb-2">Price ($100 default)</label>
//                 <input
//                   type="range"
//                   min="100"
//                   max="500"
//                   value={attendee.price}
//                   onChange={(e) => handleSliderChange(index, e.target.value)}
//                   className="w-full"
//                 />
//                 <span className="block text-gray-700">Selected Price: ${attendee.price}</span>
//               </div>
//             </div>
//           ))}
//           <button
//             className="bg-green-600 text-white font-bold py-2 px-6 rounded-full hover:bg-green-700 transition duration-300"
//           >
//             Purchase
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Register;


// import React, { useState } from 'react';

// const Register = () => {
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     phone: '',
//     adults: 1,
//     kids: 0,
//   });
//   const [formErrors, setFormErrors] = useState({});
//   const [isFormValid, setIsFormValid] = useState(false);

//   // Validation function for the form fields
//   const validateForm = () => {
//     const errors = {};

//     if (formData.firstName.length < 4 || formData.firstName.length > 30) {
//       errors.firstName = 'First Name must be between 4 and 30 characters';
//     }
//     if (formData.lastName.length < 4 || formData.lastName.length > 30) {
//       errors.lastName = 'Last Name must be between 4 and 30 characters';
//     }
//     const emailPattern = /^[^\s@]+\@[^\s@]+\.[^\s@]+$/;
//     if (!emailPattern.test(formData.email)) {
//       errors.email = 'Invalid email address';
//     }
//     const phonePattern = /^\(\d{3}\) \d{3}-\d{4}$/; // US phone number pattern
//     if (!phonePattern.test(formData.phone)) {
//       errors.phone = 'Invalid phone number. Format: (123) 456-7890';
//     }
//     if (formData.adults < 1 || formData.adults > 6) {
//       errors.adults = 'Number of adults must be between 1 and 6';
//     }
//     if (formData.kids < 0 || formData.adults + formData.kids > 6) {
//       errors.kids = 'Total number of attendees (adults + kids) cannot exceed 6';
//     }

//     setFormErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handlePeopleSelection = (e) => {
//     const { name, value } = e.target;
//     const updatedValue = parseInt(value, 10);
//     setFormData({ ...formData, [name]: updatedValue });
//   };

//   const handlePhoneInputChange = (e) => {
//     let formattedPhone = e.target.value.replace(/\D/g, '');
//     if (formattedPhone.length > 3 && formattedPhone.length <= 6) {
//       formattedPhone = `(${formattedPhone.slice(0, 3)}) ${formattedPhone.slice(3)}`;
//     } else if (formattedPhone.length > 6) {
//       formattedPhone = `(${formattedPhone.slice(0, 3)}) ${formattedPhone.slice(3, 6)}-${formattedPhone.slice(6, 10)}`;
//     }
//     setFormData({ ...formData, phone: formattedPhone });
//   };

//   const handleFormSubmit = () => {
//     if (validateForm()) {
//       setIsFormValid(true);
//     }
//   };

//   return (
//     <div className="w-11/12 mx-auto bg-gray-50 flex flex-col justify-center items-center p-8" style={{ minHeight: '100vh', overflow: 'auto' }}>
//       <div className="w-full md:w-1/2 max-h-[80vh] overflow-y-auto">
//         <h2 className="text-3xl font-bold text-gray-900 mb-6">Event Registration</h2>
//         <form className="space-y-4">
//           {/* First Name Field */}
//           <div>
//             <input
//               type="text"
//               name="firstName"
//               value={formData.firstName}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="First Name"
//             />
//             {formErrors.firstName && <p className="text-red-500 text-sm">{formErrors.firstName}</p>}
//           </div>
          
//           {/* Last Name Field */}
//           <div>
//             <input
//               type="text"
//               name="lastName"
//               value={formData.lastName}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Last Name"
//             />
//             {formErrors.lastName && <p className="text-red-500 text-sm">{formErrors.lastName}</p>}
//           </div>

//           {/* Email Field */}
//           <div>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Email"
//             />
//             {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>}
//           </div>

//           {/* Phone Field with US number formatting */}
//           <div>
//             <input
//               type="tel"
//               name="phone"
//               value={formData.phone}
//               onChange={handlePhoneInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Phone (123-456-7890)"
//             />
//             {formErrors.phone && <p className="text-red-500 text-sm">{formErrors.phone}</p>}
//           </div>

//           {/* Number of Adults */}
//           <div>
//             <input
//               type="number"
//               name="adults"
//               value={formData.adults}
//               onChange={handlePeopleSelection}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Number of Adults"
//               min="1"
//               max="6"
//             />
//             {formErrors.adults && <p className="text-red-500 text-sm">{formErrors.adults}</p>}
//           </div>

//           {/* Number of Kids */}
//           <div>
//             <input
//               type="number"
//               name="kids"
//               value={formData.kids}
//               onChange={handlePeopleSelection}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Number of Kids"
//               min="0"
//               max={6 - formData.adults}
//             />
//             {formErrors.kids && <p className="text-red-500 text-sm">{formErrors.kids}</p>}
//           </div>

//           {/* Purchase Button */}
//           <button
//             type="button"
//             onClick={handleFormSubmit}
//             className={`bg-green-600 text-white font-bold py-2 px-6 rounded-full hover:bg-green-700 transition duration-300 ${
//               Object.keys(formErrors).length === 0 ? '' : 'opacity-50 cursor-not-allowed'
//             }`}
//             disabled={Object.keys(formErrors).length !== 0}
//           >
//             Purchase
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Register;



// import React, { useState } from 'react';
// import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid'; // Import the UUID library

// const Register = () => {
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     phoneRaw: '', // Store the raw phone number (unformatted)
//     phoneFormatted: '', // Store the formatted phone number for display
//     adults: 1,
//     kids: 0,
//     amount: 10, // Default amount is $10
//     uuid: uuidv4(), // Generate a unique UUID for the user
//   });
//   const [formErrors, setFormErrors] = useState({});
//   const [isFormValid, setIsFormValid] = useState(false);
//   const [popupData, setPopupData] = useState(null); // State for storing popup data
//   const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
//   const [showDisclaimer, setShowDisclaimer] = useState(false); // For showing disclaimer popup
//   const [acceptedTerms, setAcceptedTerms] = useState(false); // For tracking if user accepted terms
//   const [loading, setLoading] = useState(false); // Loading state for API requests

//   // Validation function for the form fields
//   const validateField = (name, value) => {
//     const errors = { ...formErrors };

//     switch (name) {
//       case 'firstName':
//         if (value.length < 4 || value.length > 30) {
//           errors.firstName = 'First Name must be between 4 and 30 characters';
//         } else {
//           delete errors.firstName;
//         }
//         break;
//       case 'lastName':
//         if (value.length < 4 || value.length > 30) {
//           errors.lastName = 'Last Name must be between 4 and 30 characters';
//         } else {
//           delete errors.lastName;
//         }
//         break;
//       case 'email':
//         const emailPattern = /^[^\s@]+\@[^\s@]+\.[^\s@]+$/;
//         if (!emailPattern.test(value)) {
//           errors.email = 'Invalid email address';
//         } else {
//           delete errors.email;
//         }
//         break;
//       case 'phoneRaw': // Validate the raw phone number
//         const phonePattern = /^\d{10}$/; // US phone number pattern (10 digits)
//         if (!phonePattern.test(value)) {
//           errors.phone = 'Invalid phone number. Format: 1234567890';
//         } else {
//           delete errors.phone;
//         }
//         break;
//       case 'adults':
//         if (value < 1 || value > 6) {
//           errors.adults = 'Number of adults must be between 1 and 6';
//         } else {
//           delete errors.adults;
//         }
//         break;
//       case 'kids':
//         if (value < 0 || value + formData.adults > 6) {
//           errors.kids = 'Total number of attendees (adults + kids) cannot exceed 6';
//         } else {
//           delete errors.kids;
//         }
//         break;
//       case 'amount':
//         if (value < 10 || value > 5000) {
//           errors.amount = 'Amount must be between $10 and $5000';
//         } else {
//           delete errors.amount;
//         }
//         break;
//       default:
//         break;
//     }

//     setFormErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//     validateField(name, value); // Revalidate the field when it changes
//   };

//   const handlePeopleSelection = (e) => {
//     const { name, value } = e.target;
//     const updatedValue = parseInt(value, 10);
//     setFormData({ ...formData, [name]: updatedValue });
//     validateField(name, updatedValue); // Revalidate the field when it changes
//   };

//   // Format the phone number for display
//   const formatPhoneNumber = (rawPhone) => {
//     let formattedPhone = rawPhone.replace(/\D/g, ''); // Remove non-digits
//     if (formattedPhone.length > 3 && formattedPhone.length <= 6) {
//       formattedPhone = `(${formattedPhone.slice(0, 3)}) ${formattedPhone.slice(3)}`;
//     } else if (formattedPhone.length > 6) {
//       formattedPhone = `(${formattedPhone.slice(0, 3)}) ${formattedPhone.slice(3, 6)}-${formattedPhone.slice(6, 10)}`;
//     }
//     return formattedPhone;
//   };

//   // Handle phone number input change
//   const handlePhoneInputChange = (e) => {
//     const rawPhone = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
//     const formattedPhone = formatPhoneNumber(rawPhone); // Format the phone number for display

//     setFormData({ ...formData, phoneRaw: rawPhone, phoneFormatted: formattedPhone });
//     validateField('phoneRaw', rawPhone); // Validate the raw phone number
//   };

//   // Show disclaimer popup when Purchase button is clicked
//   const handleFormSubmit = () => {
//     if (Object.keys(formErrors).length === 0) {
//       setShowDisclaimer(true);
//     }
//   };

//   const handleProceed = async () => {
//     setLoading(true);
//     try {
//       // Call the registration API with the unformatted phone number
//       const registrationResponse = await axios.post(
//         'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/registration',
//         {
//           uuid: formData.uuid, // Send generated UUID
//           first_name: formData.firstName,
//           last_name: formData.lastName,
//           email: formData.email,
//           phone_no: formData.phoneRaw, // Send the raw, unformatted phone number
//           no_adults: formData.adults,
//           no_kids: formData.kids,
//           amount: formData.amount,
//           transaction_type: "Cash"
//         },
//         {
//           headers: {
//             Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`,
//           },
//         }
//       );

//       const insertedData = registrationResponse.data.data; // Extract data from the response
//       const uuid = insertedData[0].uuid; // Get the UUID from the inserted data

//       // Trigger the updateStatus API
//       const updateStatusResponse = await axios.post(
//         'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/updateStatus',
//         {
//           uuid: uuid, // Use the UUID from the registration response
//           transaction_status: 'success',
//         },
//         {
//           headers: {
//             Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`,
//           },
//         }
//       );

//       // Set popup data from updateStatus API response and show the popup
//       setPopupData(updateStatusResponse.data);
//       setShowPopup(true);
//       setShowDisclaimer(false);
//       setLoading(false);
//     } catch (error) {
//       console.error('Error in form submission:', error);
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="w-11/12 mx-auto bg-gray-50 flex flex-col justify-center items-center p-8" style={{ minHeight: '100vh', overflow: 'auto' }}>
//       <div className="w-full md:w-1/2 max-h-[80vh] overflow-y-auto">
//         <h2 className="text-3xl font-bold text-gray-900 mb-6">Event Registration</h2>
//         <form className="space-y-4">
//           {/* First Name Field */}
//           <div>
//             <input
//               type="text"
//               name="firstName"
//               value={formData.firstName}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="First Name"
//             />
//             {formErrors.firstName && <p className="text-red-500 text-sm">{formErrors.firstName}</p>}
//           </div>
          
//           {/* Last Name Field */}
//           <div>
//             <input
//               type="text"
//               name="lastName"
//               value={formData.lastName}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Last Name"
//             />
//             {formErrors.lastName && <p className="text-red-500 text-sm">{formErrors.lastName}</p>}
//           </div>

//           {/* Email Field */}
//           <div>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Email"
//             />
//             {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>}
//           </div>

//           {/* Phone Field with US number formatting */}
//           <div>
//             <input
//               type="tel"
//               name="phoneFormatted"
//               value={formData.phoneFormatted} // Display the formatted phone number
//               onChange={handlePhoneInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Phone (123-456-7890)"
//             />
//             {formErrors.phone && <p className="text-red-500 text-sm">{formErrors.phone}</p>}
//           </div>

//           {/* Number of Adults */}
//           <div>
//             <input
//               type="number"
//               name="adults"
//               value={formData.adults}
//               onChange={handlePeopleSelection}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Number of Adults"
//               min="1"
//               max="6"
//             />
//             {formErrors.adults && <p className="text-red-500 text-sm">{formErrors.adults}</p>}
//           </div>

//           {/* Number of Kids */}
//           <div>
//             <input
//               type="number"
//               name="kids"
//               value={formData.kids}
//               onChange={handlePeopleSelection}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Number of Kids"
//               min="0"
//               max={6 - formData.adults}
//             />
//             {formErrors.kids && <p className="text-red-500 text-sm">{formErrors.kids}</p>}
//           </div>

//           {/* Amount Field */}
//           <div>
//             <input
//               type="number"
//               name="amount"
//               value={formData.amount}
//               onChange={handlePeopleSelection}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Amount ($10 - $5000)"
//               min="10"
//               max="5000"
//             />
//             {formErrors.amount && <p className="text-red-500 text-sm">{formErrors.amount}</p>}
//           </div>

//           {/* Purchase Button */}
//           <button
//             type="button"
//             onClick={handleFormSubmit}
//             className={`bg-green-600 text-white font-bold py-2 px-6 rounded-full hover:bg-green-700 transition duration-300 ${
//               Object.keys(formErrors).length === 0 ? '' : 'opacity-50 cursor-not-allowed'
//             }`}
//             disabled={Object.keys(formErrors).length !== 0}
//           >
//             Purchase
//           </button>
//         </form>
//       </div>

//       {/* Popup to display disclaimer */}
//       {showDisclaimer && (
//   <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center overflow-hidden">
//     <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full h-[80vh] overflow-y-auto">
//       <h3 className="text-2xl font-bold text-center mb-6">Please Read and Accept the Terms and Conditions</h3>
//       <div className="text-sm space-y-2 text-justify ">
//         <p>1. Attendees are hereby informed that foods prepared at this event may contain or have come into contact with common allergens, including but not limited to milk, eggs, wheat, soybean, peanuts, tree nuts, fish, lupin, mollusks, mustard, sesame, celery, sulfur dioxide, and shellfish. It is the responsibility of each attendee to inquire about the ingredients and allergen risks before consuming any food at the event to ensure their safety.</p>
//         <p>2. The consumption or sale of alcohol at the event is strictly prohibited. Any violation of this rule will result in immediate removal from the premises without any refund or further recourse. In addition, political demonstrations, flag displays, shouting, or any form of political expression are not allowed, as we aim to maintain a neutral and inclusive atmosphere for all participants. The use, possession, or display of fireworks is strictly forbidden, and any violators of this rule will be removed from the event and may face legal action for safety violations.</p>
//         <p>3. All attendees are solely responsible for the safety and security of their personal belongings. The event organizers and the Kakatiya Seva Samithi will not be held liable for any loss, theft, or damage to personal items or property during the event.</p>
//         <p>4. We expect all attendees to treat volunteers, staff, and fellow guests with respect. Any arguments, fights, or aggressive behavior toward others will not be tolerated and will result in immediate expulsion from the event. Additionally, all decisions regarding the event’s style, format, structure, or any changes will be made by the organizing committee. Attendees are expected to respect these decisions without questioning or challenging any modifications.</p>
//         <p>5. While we strive to create a safe and enjoyable environment, our liability is limited to incidents arising directly from our negligence or intentional misconduct. We will not be liable for any injury, illness, death, property loss or damage, or other harm suffered by attendees unless it is directly attributable to our actions. We also disclaim responsibility for emotional distress, mental suffering, or psychological injury, except in cases where such distress results from physical harm due to our negligence or willful misconduct, or when the injury was intentionally inflicted by organizers, volunteers, staff, contractors, or representatives. Under no circumstances will we be liable for any consequential, incidental, exemplary, or punitive damages.</p>
//         <p>6. In the event of unforeseen circumstances beyond our control, such as natural disasters, acts of terrorism, war, government restrictions, strikes, pandemics, or other force majeure events, we cannot be held liable for any disruptions or cancellations. These events may make it impossible to fulfill our obligations as planned.</p>
//         <p>7. Personal data collected during the registration process will be handled with the utmost confidentiality. We assure all participants that their personal information will not be shared with any third parties, organizations, or used for any marketing purposes. Data will solely be used for event management and to ensure the safety and organization of the event.</p>
//         <p>8. Only registered attendees will be permitted entry to the event. The number of guests allowed is strictly as per the details provided in the registration. Our volunteers will verify the admission passes upon arrival, and any individual without proper registration will not be allowed entry. No exceptions will be made.</p>
//         <p>9. By attending this event, participants acknowledge that there are inherent risks involved, including potential exposure to COVID-19, other diseases, bacteria, viruses, or pathogens. Attendees voluntarily assume all risks, which may include illness, injury, permanent disability, or death. By attending, participants waive any right to hold the organizers liable for any injury, illness, death, or property damage, whether or not related to the event or its associated services. This waiver also extends to any guests or invitees.</p>
//         <p>10. We reserve the right to deny entry or remove any individual from the event for reasons including, but not limited to, disruptive behavior, safety concerns, non-compliance with security procedures, or violation of these terms. This includes individuals under the influence of prohibited substances, engaging in disorderly conduct, or failing to provide identification or proof of age when requested.</p>
//         <p>11. Your attendance at the event signifies your understanding and acceptance of these terms and conditions. Failure to comply with these rules may result in removal without compensation or liability on our part.</p>
//         {/* Add more disclaimer text here */}
//         <div className="flex items-center mt-4">
//           <input
//             type="checkbox"
//             checked={acceptedTerms}
//             onChange={() => setAcceptedTerms(!acceptedTerms)}
//             className="mr-2"
//           />
//           <label>I agree to the terms and conditions</label>
//         </div>
//       </div>
//       <button
//         type="button"
//         onClick={handleProceed}
//         className={`mt-6 bg-green-600 text-white py-2 px-4 rounded-full hover:bg-green-700 transition duration-300 w-full ${
//           acceptedTerms ? '' : 'opacity-50 cursor-not-allowed'
//         }`}
//         disabled={!acceptedTerms}
//       >
//         Proceed
//       </button>
//     </div>
//   </div>
// )}


//       {/* Loader while API calls are being made */}
//       {loading && (
//         <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50">
//           <div className="loader">Loading...</div>
//         </div>
//       )}

//       {/* Popup to display response details */}
//       {showPopup && (
//         <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
//           <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
//             <h3 className="text-2xl font-bold text-center mb-6">Registration Successful</h3>
//             <div className="space-y-2">
//               <p><strong>Name:</strong> {popupData.first_name} {popupData.last_name}</p>
//               <p><strong>Email:</strong> {popupData.email}</p>
//             </div>
//             <table className="w-full text-left mt-4 border-collapse">
//               <thead>
//                 <tr>
//                   <th className="border-b-2 py-2 font-semibold">Details</th>
//                   <th className="border-b-2 py-2 font-semibold">Amount</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className="py-2">Amount</td>
//                   <td className="py-2">${popupData.amount}</td>
//                 </tr>
//                 <tr>
//                   <td className="py-2">Transaction Type</td>
//                   <td className="py-2">{popupData.transaction_type}</td>
//                 </tr>
//               </tbody>
//             </table>
//             <div className="flex justify-center mt-6">
//               <img
//                 src={popupData.qr_code}
//                 alt="QR Code"
//                 className="w-32 h-32"
//               />
//             </div>
//             <button
//               onClick={() => setShowPopup(false)}
//               className="mt-6 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 w-full"
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Register;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid'; // Import the UUID library
// import { supabase } from '../supabaseClient'; // Import Supabase client
// import 'react-phone-input-2/lib/style.css'; // Import the CSS for the phone input component
// import PhoneInput from 'react-phone-input-2'; // Import the PhoneInput component
// import { FaCheckCircle } from 'react-icons/fa'; // Import the tick mark icon

// const Register = () => {
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     phoneRaw: '', // Store the raw phone number (including country code)
//     otp: '', // New field for storing OTP
//     otpVerified: false, // Field to track if OTP is verified
//     adults: 1,
//     kids: 0,
//     amount: 10, // Default amount is $10
//     uuid: uuidv4(), // Generate a unique UUID for the user
//   });
//   const [formErrors, setFormErrors] = useState({});
//   const [popupData, setPopupData] = useState(null); // State for storing popup data
//   const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
//   const [showDisclaimer, setShowDisclaimer] = useState(false); // For showing disclaimer popup
//   const [acceptedTerms, setAcceptedTerms] = useState(false); // For tracking if user accepted terms
//   const [loading, setLoading] = useState(false); // Loading state for API requests
//   const [otpSent, setOtpSent] = useState(false); // Track if OTP is sent
//   const [otpError, setOtpError] = useState(''); // Error message for OTP verification

//   // Validation function for the form fields
//   const validateField = (name, value) => {
//     const errors = { ...formErrors };

//     switch (name) {
//       case 'firstName':
//         if (value.length < 4 || value.length > 30) {
//           errors.firstName = 'First Name must be between 4 and 30 characters';
//         } else {
//           delete errors.firstName;
//         }
//         break;
//       case 'lastName':
//         if (value.length < 4 || value.length > 30) {
//           errors.lastName = 'Last Name must be between 4 and 30 characters';
//         } else {
//           delete errors.lastName;
//         }
//         break;
//       case 'email':
//         const emailPattern = /^[^\s@]+\@[^\s@]+\.[^\s@]+$/;
//         if (!emailPattern.test(value)) {
//           errors.email = 'Invalid email address';
//         } else {
//           delete errors.email;
//         }
//         break;
//       case 'phoneRaw': // Validate the raw phone number (now includes country code)
//         if (!value || value.length < 10) {
//           errors.phone = 'Invalid phone number';
//         } else {
//           delete errors.phone;
//         }
//         break;
//       default:
//         break;
//     }

//     setFormErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//     validateField(name, value); // Revalidate the field when it changes
//   };

//   // Handle phone number input change (including country code)
//   const handlePhoneInputChange = (value) => {
//     setFormData({ ...formData, phoneRaw: value });
//     validateField('phoneRaw', value); // Validate the phone number
//   };

//   // Function to send OTP using Supabase (including country code)
//   const sendOtp = async () => {
//     setLoading(true);
//     const { error } = await supabase.auth.signInWithOtp({
//       phone: `+${formData.phoneRaw}`, // Send the phone number with the country code
//     });

//     if (error) {
//       setOtpError('Error sending OTP: ' + error.message);
//       setLoading(false);
//     } else {
//       setOtpSent(true);
//       setLoading(false);
//     }
//   };

//   // Function to verify OTP using Supabase
//   const verifyOtp = async () => {
//     const { error } = await supabase.auth.verifyOtp({
//       phone: `+${formData.phoneRaw}`, // Verify using the full phone number
//       token: formData.otp, // OTP entered by user
//       type: 'sms',
//     });

//     if (error) {
//       setOtpError('Invalid OTP. Please try again.');
//     } else {
//       setOtpError(''); // Clear any previous OTP errors
//       setFormData({ ...formData, otpVerified: true });
//     }
//   };

//   const handleFormSubmit = () => {
//     if (Object.keys(formErrors).length === 0 && formData.otpVerified) {
//       setShowDisclaimer(true);
//     }
//   };

//   const handleProceed = async () => {
//     setLoading(true);
//     try {
//       const registrationResponse = await axios.post(
//         'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/registration',
//         {
//           uuid: formData.uuid, // Send generated UUID
//           first_name: formData.firstName,
//           last_name: formData.lastName,
//           email: formData.email,
//           phone_no: formData.phoneRaw, // Send the raw, unformatted phone number
//           no_adults: formData.adults,
//           no_kids: formData.kids,
//           amount: formData.amount,
//           transaction_type: "Cash"
//         },
//         {
//           headers: {
//             Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`,
//           },
//         }
//       );

//       const insertedData = registrationResponse.data.data; // Extract data from the response
//       const uuid = insertedData[0].uuid; // Get the UUID from the inserted data

//       const stripePaymentUrl = `https://buy.stripe.com/test_cN24jZ8g977Ze52fYY?prefilled_email=${formData.email}&client_reference_id=${uuid}`;
//       window.location.href = stripePaymentUrl;
//     } catch (error) {
//       console.error('Error in form submission:', error);
//       setLoading(false);
//     }
//   };

//   // Handle Stripe redirection response
//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const transactionStatus = urlParams.get('transaction_status');
//     const uuid = urlParams.get('client_reference_id');

//     if (transactionStatus && uuid) {
//       const updateTransactionStatus = async () => {
//         setLoading(true);
//         try {
//           const updateStatusResponse = await axios.post(
//             'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/updateStatus',
//             {
//               uuid: uuid, // Use the UUID from the registration response
//               transaction_status: transactionStatus,
//             },
//             {
//               headers: {
//                 Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`,
//               },
//             }
//           );

//           setPopupData(updateStatusResponse.data);
//           setShowPopup(true);
//           setLoading(false);
//         } catch (error) {
//           console.error('Error in updating transaction status:', error);
//           setLoading(false);
//         }
//       };

//       updateTransactionStatus();
//     }
//   }, []);

//   return (
//     <div className="w-11/12 mx-auto bg-gray-50 flex flex-col justify-center items-center p-8" style={{ minHeight: '100vh', overflow: 'auto' }}>
//       <div className="w-full md:w-1/2 max-h-[80vh] overflow-y-auto">
//         <h2 className="text-3xl font-bold text-gray-900 mb-6">Event Registration</h2>
//         <form className="space-y-4">
//           {/* First Name Field */}
//           <div>
//             <input
//               type="text"
//               name="firstName"
//               value={formData.firstName}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="First Name"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.firstName && <p className="text-red-500 text-sm">{formErrors.firstName}</p>}
//           </div>

//           {/* Last Name Field */}
//           <div>
//             <input
//               type="text"
//               name="lastName"
//               value={formData.lastName}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Last Name"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.lastName && <p className="text-red-500 text-sm">{formErrors.lastName}</p>}
//           </div>

//           {/* Email Field */}
//           <div>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Email"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>}
//           </div>

//           {/* Phone Field with Country Code selection */}
//           <div className="flex items-center relative">
//             <PhoneInput
//               country={'us'} // Default country
//               value={formData.phoneRaw} // Raw phone number with country code
//               onChange={handlePhoneInputChange}
//               inputClass="w-full border border-gray-300 rounded p-2 pr-12" // Add padding to the right for the tick mark
//               placeholder="Enter phone number"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formData.otpVerified && (
//               <FaCheckCircle className="absolute right-4 top-3 text-green-500" /> // Display tick mark on successful OTP verification
//             )}
//             {!formData.otpVerified && (
//               <button
//                 type="button"
//                 onClick={sendOtp}
//                 className="ml-4 bg-blue-500 text-white font-bold py-2 px-4 rounded"
//                 disabled={otpSent} // Disable the button once OTP is sent
//               >
//                 {otpSent ? 'OTP Sent' : 'Verify'}
//               </button>
//             )}
//           </div>
//           {formErrors.phone && <p className="text-red-500 text-sm">{formErrors.phone}</p>}
//           {otpError && <p className="text-red-500 text-sm">{otpError}</p>}

//           {/* OTP Input Field */}
//           {otpSent && !formData.otpVerified && (
//             <div>
//               <input
//                 type="text"
//                 name="otp"
//                 value={formData.otp}
//                 onChange={handleInputChange}
//                 className="w-full border border-gray-300 rounded p-2"
//                 placeholder="Enter OTP"
//               />
//               <button
//                 type="button"
//                 onClick={verifyOtp}
//                 className="mt-4 bg-green-500 text-white font-bold py-2 px-6 rounded"
//               >
//                 Verify OTP
//               </button>
//             </div>
//           )}

//           {/* Number of Adults */}
//           <div>
//             <input
//               type="number"
//               name="adults"
//               value={formData.adults}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Number of Adults"
//               min="1"
//               max="6"
//               disabled={!formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.adults && <p className="text-red-500 text-sm">{formErrors.adults}</p>}
//           </div>

//           {/* Number of Kids */}
//           <div>
//             <input
//               type="number"
//               name="kids"
//               value={formData.kids}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Number of Kids"
//               min="0"
//               max={6 - formData.adults}
//               disabled={!formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.kids && <p className="text-red-500 text-sm">{formErrors.kids}</p>}
//           </div>

//           {/* Amount Field */}
//           <div>
//             <input
//               type="number"
//               name="amount"
//               value={formData.amount}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Amount ($10 - $5000)"
//               min="10"
//               max="5000"
//               disabled={!formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.amount && <p className="text-red-500 text-sm">{formErrors.amount}</p>}
//           </div>

//           {/* Purchase Button */}
//           <button
//             type="button"
//             onClick={handleFormSubmit}
//             className={`bg-green-600 text-white font-bold py-2 px-6 rounded-full hover:bg-green-700 transition duration-300 ${
//               Object.keys(formErrors).length === 0 && formData.otpVerified ? '' : 'opacity-50 cursor-not-allowed'
//             }`}
//             disabled={Object.keys(formErrors).length !== 0 || !formData.otpVerified}
//           >
//             Purchase
//           </button>
//         </form>
//       </div>

//       {/* Popup to display disclaimer */}
//       {showDisclaimer && (
//         <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center overflow-hidden">
//           <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full h-[80vh] overflow-y-auto">
//             <h3 className="text-2xl font-bold text-center mb-6">Please Read and Accept the Terms and Conditions</h3>
//             <div className="text-sm space-y-2 text-justify">
//               <p>1. Attendees are responsible for ensuring their own safety and the safety of others. Event organizers are not liable for any accidents or injuries.</p>
//               <p>2. No outside food or beverages are allowed at the event.</p>
//               <p>3. Attendees must comply with all event rules and regulations.</p>
//               <p>4. The consumption of alcohol is strictly prohibited during the event.</p>
//               <p>5. The event organizers reserve the right to refuse entry or remove any attendee who does not comply with the rules.</p>
//               <p>6. Photography and video recording are allowed, but attendees must respect the privacy of others.</p>
//               <div className="flex items-center mt-4">
//                 <input
//                   type="checkbox"
//                   checked={acceptedTerms}
//                   onChange={() => setAcceptedTerms(!acceptedTerms)}
//                   className="mr-2"
//                 />
//                 <label>I agree to the terms and conditions</label>
//               </div>
//             </div>
//             <button
//               type="button"
//               onClick={handleProceed}
//               className={`mt-6 bg-green-600 text-white py-2 px-4 rounded-full hover:bg-green-700 transition duration-300 w-full ${
//                 acceptedTerms ? '' : 'opacity-50 cursor-not-allowed'
//               }`}
//               disabled={!acceptedTerms}
//             >
//               Proceed
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Loader while API calls are being made */}
//       {loading && (
//         <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50">
//           <div className="loader">Loading...</div>
//         </div>
//       )}

//       {/* Popup to display response details */}
//       {showPopup && (
//         <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
//           <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
//             <h3 className="text-2xl font-bold text-center mb-6">Registration Successful</h3>
//             <div className="space-y-2">
//               <p><strong>Name:</strong> {popupData.first_name} {popupData.last_name}</p>
//               <p><strong>Email:</strong> {popupData.email}</p>
//             </div>
//             <table className="w-full text-left mt-4 border-collapse">
//               <thead>
//                 <tr>
//                   <th className="border-b-2 py-2 font-semibold">Details</th>
//                   <th className="border-b-2 py-2 font-semibold">Amount</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className="py-2">Amount</td>
//                   <td className="py-2">${popupData.amount}</td>
//                 </tr>
//                 <tr>
//                   <td className="py-2">Transaction Type</td>
//                   <td className="py-2">{popupData.transaction_type}</td>
//                 </tr>
//               </tbody>
//             </table>
//             <div className="flex justify-center mt-6">
//               <img
//                 src={popupData.qr_code}
//                 alt="QR Code"
//                 className="w-32 h-32"
//               />
//             </div>
//             <button
//               onClick={() => setShowPopup(false)}
//               className="mt-6 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 w-full"
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Register;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid'; // Import the UUID library
// import { supabase } from '../supabaseClient'; // Import Supabase client
// import 'react-phone-input-2/lib/style.css'; // Import the CSS for the phone input component
// import PhoneInput from 'react-phone-input-2'; // Import the PhoneInput component
// import { FaCheckCircle } from 'react-icons/fa'; // Import the tick mark icon
// import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'; // Import Stripe elements
// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe('pk_test_51Ni6txCSxXRGUMtq79PZvPNYhKaypct1K24GVDQrrYOQCFR0QiC0gHanJI4fkzwmZ4jB5oPb1eH6foKaV9SlGFm1006k3sug3P'); // Replace with your Stripe public key

// const Register = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     phoneRaw: '', // Store the raw phone number (including country code)
//     otp: '', // New field for storing OTP
//     otpVerified: false, // Field to track if OTP is verified
//     adults: 1,
//     kids: 0,
//     amount: 10, // Default amount is $10
//     uuid: uuidv4(), // Generate a unique UUID for the user
//   });
//   const [formErrors, setFormErrors] = useState({});
//   const [popupData, setPopupData] = useState(null); // State for storing popup data
//   const [showPopup, setShowPopup] = useState(false); // State for controlling popup visibility
//   const [showDisclaimer, setShowDisclaimer] = useState(false); // For showing disclaimer popup
//   const [acceptedTerms, setAcceptedTerms] = useState(false); // For tracking if user accepted terms
//   const [loading, setLoading] = useState(false); // Loading state for API requests
//   const [otpSent, setOtpSent] = useState(false); // Track if OTP is sent
//   const [otpError, setOtpError] = useState(''); // Error message for OTP verification
//   const [clientSecret, setClientSecret] = useState(null); // Store client secret from Stripe

//   // Validation function for the form fields
//   const validateField = (name, value) => {
//     const errors = { ...formErrors };

//     switch (name) {
//       case 'firstName':
//         if (value.length < 4 || value.length > 30) {
//           errors.firstName = 'First Name must be between 4 and 30 characters';
//         } else {
//           delete errors.firstName;
//         }
//         break;
//       case 'lastName':
//         if (value.length < 4 || value.length > 30) {
//           errors.lastName = 'Last Name must be between 4 and 30 characters';
//         } else {
//           delete errors.lastName;
//         }
//         break;
//       case 'email':
//         const emailPattern = /^[^\s@]+\@[^\s@]+\.[^\s@]+$/;
//         if (!emailPattern.test(value)) {
//           errors.email = 'Invalid email address';
//         } else {
//           delete errors.email;
//         }
//         break;
//       case 'phoneRaw': // Validate the raw phone number (now includes country code)
//         if (!value || value.length < 10) {
//           errors.phone = 'Invalid phone number';
//         } else {
//           delete errors.phone;
//         }
//         break;
//       default:
//         break;
//     }

//     setFormErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//     validateField(name, value); // Revalidate the field when it changes
//   };

//   // Handle phone number input change (including country code)
//   const handlePhoneInputChange = (value) => {
//     setFormData({ ...formData, phoneRaw: value });
//     validateField('phoneRaw', value); // Validate the phone number
//   };

//   // Function to send OTP using Supabase (including country code)
//   const sendOtp = async () => {
//     setLoading(true);
//     const { error } = await supabase.auth.signInWithOtp({
//       phone: `+${formData.phoneRaw}`, // Send the phone number with the country code
//     });

//     if (error) {
//       setOtpError('Error sending OTP: ' + error.message);
//       setLoading(false);
//     } else {
//       setOtpSent(true);
//       setLoading(false);
//     }
//   };

//   // Function to verify OTP using Supabase
//   const verifyOtp = async () => {
//     const { error } = await supabase.auth.verifyOtp({
//       phone: `+${formData.phoneRaw}`, // Verify using the full phone number
//       token: formData.otp, // OTP entered by user
//       type: 'sms',
//     });

//     if (error) {
//       setOtpError('Invalid OTP. Please try again.');
//     } else {
//       setOtpError(''); // Clear any previous OTP errors
//       setFormData({ ...formData, otpVerified: true });
//     }
//   };

//   // Function to create a payment intent in your Supabase backend
//   const createPaymentIntent = async () => {
//     try {
//       const response = await axios.post(
//         'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/createPaymentIntent',
//         {
//           uuid: formData.uuid,
//           email: formData.email,
//           amount: formData.amount,
//         },
//         {
//           headers: {
//             Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`, // Replace with your JWT token
//           },
//         }
//       );
//       setClientSecret(response.data.client_secret); // Store the client secret returned from the backend
//     } catch (error) {
//       console.error('Error creating payment intent:', error);
//     }
//   };

//   const handleFormSubmit = async () => {
//     if (Object.keys(formErrors).length === 0 && formData.otpVerified) {
//       setShowDisclaimer(true);
//     }
//   };

//   // Trigger the payment first, then registration API and updateStatus API
//   const handleProceed = async () => {
//     setLoading(true);
//     try {
//       // Step 1: Create a payment intent
//       await createPaymentIntent();
//       console.log(clientSecret)
//       // Step 2: Handle the payment process
//       const cardElement = elements.getElement(CardElement);
//       const paymentResult = await stripe.confirmCardPayment(clientSecret, {
//         payment_method: {
//           card: cardElement,
//           billing_details: {
//             name: `${formData.firstName} ${formData.lastName}`,
//             email: formData.email,
//           },
//         },
//       });

//       if (paymentResult.error) {
//         console.error('Error during payment confirmation:', paymentResult.error.message);
//         setLoading(false);
//       } else if (paymentResult.paymentIntent.status === 'succeeded') {
//         // Step 3: Trigger the registration API after successful payment
//         const registrationResponse = await axios.post(
//           'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/registration',
//           {
//             uuid: formData.uuid,
//             first_name: formData.firstName,
//             last_name: formData.lastName,
//             email: formData.email,
//             phone_no: formData.phoneRaw,
//             no_adults: formData.adults,
//             no_kids: formData.kids,
//             amount: formData.amount,
//             transaction_type: "Card"
//           },
//           {
//             headers: {
//               Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`,
//             },
//           }
//         );

//         const insertedData = registrationResponse.data.data;
//         const uuid = insertedData[0].uuid;

//         // Step 4: Trigger the updateStatus API
//         const updateStatusResponse = await axios.post(
//           'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/updateStatus',
//           {
//             uuid: formData.uuid,
//             transaction_status: 'success',
//           },
//           {
//             headers: {
//               Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`,
//             },
//           }
//         );

//         setPopupData(updateStatusResponse.data);
//         setShowPopup(true);
//         setLoading(false);
//       }
//     } catch (error) {
//       console.error('Error in registration or payment:', error);
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="w-11/12 mx-auto bg-gray-50 flex flex-col justify-center items-center p-8" style={{ minHeight: '100vh', overflow: 'auto' }}>
//       <div className="w-full md:w-1/2 max-h-[80vh] overflow-y-auto">
//         <h2 className="text-3xl font-bold text-gray-900 mb-6">Event Registration</h2>
//         <form className="space-y-4">
//           {/* First Name Field */}
//           <div>
//             <input
//               type="text"
//               name="firstName"
//               value={formData.firstName}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="First Name"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.firstName && <p className="text-red-500 text-sm">{formErrors.firstName}</p>}
//           </div>

//           {/* Last Name Field */}
//           <div>
//             <input
//               type="text"
//               name="lastName"
//               value={formData.lastName}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Last Name"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.lastName && <p className="text-red-500 text-sm">{formErrors.lastName}</p>}
//           </div>

//           {/* Email Field */}
//           <div>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Email"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>}
//           </div>

//           {/* Phone Field with Country Code selection */}
//           <div className="flex items-center relative">
//             <PhoneInput
//               country={'us'} // Default country
//               value={formData.phoneRaw} // Raw phone number with country code
//               onChange={handlePhoneInputChange}
//               inputClass="w-full border border-gray-300 rounded p-2 pr-12" // Add padding to the right for the tick mark
//               placeholder="Enter phone number"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formData.otpVerified && (
//               <FaCheckCircle className="absolute right-4 top-3 text-green-500" /> // Display tick mark on successful OTP verification
//             )}
//             {!formData.otpVerified && (
//               <button
//                 type="button"
//                 onClick={sendOtp}
//                 className="ml-4 bg-blue-500 text-white font-bold py-2 px-4 rounded"
//                 disabled={otpSent} // Disable the button once OTP is sent
//               >
//                 {otpSent ? 'OTP Sent' : 'Verify'}
//               </button>
//             )}
//           </div>
//           {formErrors.phone && <p className="text-red-500 text-sm">{formErrors.phone}</p>}
//           {otpError && <p className="text-red-500 text-sm">{otpError}</p>}

//           {/* OTP Input Field */}
//           {otpSent && !formData.otpVerified && (
//             <div>
//               <input
//                 type="text"
//                 name="otp"
//                 value={formData.otp}
//                 onChange={handleInputChange}
//                 className="w-full border border-gray-300 rounded p-2"
//                 placeholder="Enter OTP"
//               />
//               <button
//                 type="button"
//                 onClick={verifyOtp}
//                 className="mt-4 bg-green-500 text-white font-bold py-2 px-6 rounded"
//               >
//                 Verify OTP
//               </button>
//             </div>
//           )}

//           {/* Number of Adults */}
//           <div>
//             <input
//               type="number"
//               name="adults"
//               value={formData.adults}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Number of Adults"
//               min="1"
//               max="6"
//               disabled={!formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.adults && <p className="text-red-500 text-sm">{formErrors.adults}</p>}
//           </div>

//           {/* Number of Kids */}
//           <div>
//             <input
//               type="number"
//               name="kids"
//               value={formData.kids}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Number of Kids"
//               min="0"
//               max={6 - formData.adults}
//               disabled={!formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.kids && <p className="text-red-500 text-sm">{formErrors.kids}</p>}
//           </div>

//           {/* Amount Field */}
//           <div>
//             <input
//               type="number"
//               name="amount"
//               value={formData.amount}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Amount ($10 - $5000)"
//               min="10"
//               max="5000"
//               disabled={!formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.amount && <p className="text-red-500 text-sm">{formErrors.amount}</p>}
//           </div>

//           {/* Card Input for Stripe */}
//           <CardElement />

//           {/* Purchase Button */}
//           <button
//             type="button"
//             onClick={handleFormSubmit}
//             className={`bg-green-600 text-white font-bold py-2 px-6 rounded-full hover:bg-green-700 transition duration-300 ${
//               Object.keys(formErrors).length === 0 && formData.otpVerified ? '' : 'opacity-50 cursor-not-allowed'
//             }`}
//             disabled={Object.keys(formErrors).length !== 0 || !formData.otpVerified}
//           >
//             Purchase
//           </button>
//         </form>
//       </div>

//       {/* Disclaimer and other popups */}
//       {showDisclaimer && (
//         <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center overflow-hidden">
//           <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full h-[80vh] overflow-y-auto">
//             <h3 className="text-2xl font-bold text-center mb-6">Please Read and Accept the Terms and Conditions</h3>
//             <div className="text-sm space-y-2 text-justify">
//               <p>1. Attendees are responsible for ensuring their own safety and the safety of others. Event organizers are not liable for any accidents or injuries.</p>
//               <p>2. No outside food or beverages are allowed at the event.</p>
//               <p>3. Attendees must comply with all event rules and regulations.</p>
//               <p>4. The consumption of alcohol is strictly prohibited during the event.</p>
//               <p>5. The event organizers reserve the right to refuse entry or remove any attendee who does not comply with the rules.</p>
//               <p>6. Photography and video recording are allowed, but attendees must respect the privacy of others.</p>
//               <div className="flex items-center mt-4">
//                 <input
//                   type="checkbox"
//                   checked={acceptedTerms}
//                   onChange={() => setAcceptedTerms(!acceptedTerms)}
//                   className="mr-2"
//                 />
//                 <label>I agree to the terms and conditions</label>
//               </div>
//             </div>
//             <button
//               type="button"
//               onClick={handleProceed}
//               className={`mt-6 bg-green-600 text-white py-2 px-4 rounded-full hover:bg-green-700 transition duration-300 w-full ${
//                 acceptedTerms ? '' : 'opacity-50 cursor-not-allowed'
//               }`}
//               disabled={!acceptedTerms}
//             >
//               Proceed
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Loader while API calls are being made */}
//       {loading && (
//         <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50">
//           <div className="loader">Loading...</div>
//         </div>
//       )}

//       {/* Popup to display response details */}
//       {showPopup && (
//         <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
//           <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
//             <h3 className="text-2xl font-bold text-center mb-6">Registration Successful</h3>
//             <div className="space-y-2">
//               <p><strong>Name:</strong> {popupData.first_name} {popupData.last_name}</p>
//               <p><strong>Email:</strong> {popupData.email}</p>
//             </div>
//             <table className="w-full text-left mt-4 border-collapse">
//               <thead>
//                 <tr>
//                   <th className="border-b-2 py-2 font-semibold">Details</th>
//                   <th className="border-b-2 py-2 font-semibold">Amount</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className="py-2">Amount</td>
//                   <td className="py-2">${popupData.amount}</td>
//                 </tr>
//                 <tr>
//                   <td className="py-2">Transaction Type</td>
//                   <td className="py-2">{popupData.transaction_type}</td>
//                 </tr>
//               </tbody>
//             </table>
//             <div className="flex justify-center mt-6">
//               <img
//                 src={popupData.qr_code}
//                 alt="QR Code"
//                 className="w-32 h-32"
//               />
//             </div>
//             <button
//               onClick={() => setShowPopup(false)}
//               className="mt-6 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 w-full"
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default function RegisterWithStripe() {
//   return (
//     <Elements stripe={stripePromise}>
//       <Register />
//     </Elements>
//   );
// }


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid'; // Import the UUID library
// import { supabase } from '../supabaseClient'; // Import Supabase client
// import 'react-phone-input-2/lib/style.css'; // Import the CSS for the phone input component
// import PhoneInput from 'react-phone-input-2'; // Import the PhoneInput component
// import { FaCheckCircle } from 'react-icons/fa'; // Import the tick mark icon
// import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'; // Import Stripe elements
// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe('pk_test_51Ni6txCSxXRGUMtq79PZvPNYhKaypct1K24GVDQrrYOQCFR0QiC0gHanJI4fkzwmZ4jB5oPb1eH6foKaV9SlGFm1006k3sug3P'); // Replace with your Stripe public key

// const Register = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     phoneRaw: '', // Store the raw phone number (including country code)
//     otp: '', // New field for storing OTP
//     otpVerified: false, // Field to track if OTP is verified
//     adults: 1,
//     kids: 0,
//     amount: 10, // Default amount is $10
//     uuid: uuidv4(), // Generate a unique UUID for the user
//   });
//   const [formErrors, setFormErrors] = useState({});
//   const [popupData, setPopupData] = useState(null); // State for storing popup data
//   const [showPopup, setShowPopup] = useState(false); // State for controlling success popup visibility
//   const [showDisclaimer, setShowDisclaimer] = useState(false); // For showing disclaimer popup
//   const [showCardPopup, setShowCardPopup] = useState(false); // For showing card details popup
//   const [acceptedTerms, setAcceptedTerms] = useState(false); // For tracking if user accepted terms
//   const [loading, setLoading] = useState(false); // Loading state for API requests
//   const [otpSent, setOtpSent] = useState(false); // Track if OTP is sent
//   const [otpError, setOtpError] = useState(''); // Error message for OTP verification
//   const [clientSecret, setClientSecret] = useState(null); // Store client secret from Stripe

//   // Validation function for the form fields
//   const validateField = (name, value) => {
//     const errors = { ...formErrors };

//     switch (name) {
//       case 'firstName':
//         if (value.length < 4 || value.length > 30) {
//           errors.firstName = 'First Name must be between 4 and 30 characters';
//         } else {
//           delete errors.firstName;
//         }
//         break;
//       case 'lastName':
//         if (value.length < 4 || value.length > 30) {
//           errors.lastName = 'Last Name must be between 4 and 30 characters';
//         } else {
//           delete errors.lastName;
//         }
//         break;
//       case 'email':
//         const emailPattern = /^[^\s@]+\@[^\s@]+\.[^\s@]+$/;
//         if (!emailPattern.test(value)) {
//           errors.email = 'Invalid email address';
//         } else {
//           delete errors.email;
//         }
//         break;
//       case 'phoneRaw': // Validate the raw phone number (now includes country code)
//         if (!value || value.length < 10) {
//           errors.phone = 'Invalid phone number';
//         } else {
//           delete errors.phone;
//         }
//         break;
//       default:
//         break;
//     }

//     setFormErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//     validateField(name, value); // Revalidate the field when it changes
//   };

//   // Handle phone number input change (including country code)
//   const handlePhoneInputChange = (value) => {
//     setFormData({ ...formData, phoneRaw: value });
//     validateField('phoneRaw', value); // Validate the phone number
//   };

//   // Function to send OTP using Supabase (including country code)
//   const sendOtp = async () => {
//     setLoading(true);
//     const { error } = await supabase.auth.signInWithOtp({
//       phone: `+${formData.phoneRaw}`, // Send the phone number with the country code
//     });

//     if (error) {
//       setOtpError('Error sending OTP: ' + error.message);
//       setLoading(false);
//     } else {
//       setOtpSent(true);
//       setLoading(false);
//     }
//   };

//   // Function to verify OTP using Supabase
//   const verifyOtp = async () => {
//     const { error } = await supabase.auth.verifyOtp({
//       phone: `+${formData.phoneRaw}`, // Verify using the full phone number
//       token: formData.otp, // OTP entered by user
//       type: 'sms',
//     });

//     if (error) {
//       setOtpError('Invalid OTP. Please try again.');
//     } else {
//       setOtpError(''); // Clear any previous OTP errors
//       setFormData({ ...formData, otpVerified: true });
//     }
//   };

//   // Function to create a payment intent in your Supabase backend
//   const createPaymentIntent = async () => {
//     try {
//       const response = await axios.post(
//         'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/createPaymentIntent',
//         {
//           uuid: formData.uuid,
//           email: formData.email,
//           amount: formData.amount,
//         },
//         {
//           headers: {
//             Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`, // Replace with your JWT token
//           },
//         }
//       );
//       setClientSecret(response.data.client_secret); // Store the client secret returned from the backend
//     } catch (error) {
//       console.error('Error creating payment intent:', error);
//     }
//   };

//   const handleFormSubmit = async () => {
//     if (Object.keys(formErrors).length === 0 && formData.otpVerified) {
//       setShowDisclaimer(true);
//     }
//   };

//   // Close disclaimer popup, open card details popup
//   const handleProceed = async () => {
//     setShowDisclaimer(false); // Close T&C popup
//     setShowCardPopup(true); // Open card details popup
//   };

//   // Trigger the payment, then registration API and updateStatus API
//   const handlePayment = async () => {
//     setLoading(true);
//     try {
//       // Step 1: Create a payment intent
//       await createPaymentIntent();

//       // Step 2: Handle the payment process
//       const cardElement = elements.getElement(CardElement);
//       const paymentResult = await stripe.confirmCardPayment(clientSecret, {
//         payment_method: {
//           card: cardElement,
//           billing_details: {
//             name: `${formData.firstName} ${formData.lastName}`,
//             email: formData.email,
//           },
//         },
//       });

//       if (paymentResult.error) {
//         console.error('Error during payment confirmation:', paymentResult.error.message);
//         setLoading(false);
//       } else if (paymentResult.paymentIntent.status === 'succeeded') {
//         // Step 3: Trigger the registration API after successful payment
//         const registrationResponse = await axios.post(
//           'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/registration',
//           {
//             uuid: formData.uuid,
//             first_name: formData.firstName,
//             last_name: formData.lastName,
//             email: formData.email,
//             phone_no: formData.phoneRaw,
//             no_adults: formData.adults,
//             no_kids: formData.kids,
//             amount: formData.amount,
//             transaction_type: "Card",
//           },
//           {
//             headers: {
//               Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`,
//             },
//           }
//         );

//         const insertedData = registrationResponse.data.data;
//         const uuid = insertedData[0].uuid;

//         // Step 4: Trigger the updateStatus API
//         const updateStatusResponse = await axios.post(
//           'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/updateStatus',
//           {
//             uuid: uuid,
//             transaction_status: 'success',
//           },
//           {
//             headers: {
//               Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`,
//             },
//           }
//         );

//         setPopupData(updateStatusResponse.data);
//         setShowCardPopup(false); // Close card details popup
//         setShowPopup(true); // Show success popup
//         setLoading(false);
//       }
//     } catch (error) {
//       console.error('Error in registration or payment:', error);
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="w-11/12 mx-auto bg-gray-50 flex flex-col justify-center items-center p-8" style={{ minHeight: '100vh', overflow: 'auto' }}>
//       <div className="w-full md:w-1/2 max-h-[80vh] overflow-y-auto">
//         <h2 className="text-3xl font-bold text-gray-900 mb-6">Event Registration</h2>
//         <form className="space-y-4">
//           {/* First Name Field */}
//           <div>
//             <input
//               type="text"
//               name="firstName"
//               value={formData.firstName}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="First Name"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.firstName && <p className="text-red-500 text-sm">{formErrors.firstName}</p>}
//           </div>

//           {/* Last Name Field */}
//           <div>
//             <input
//               type="text"
//               name="lastName"
//               value={formData.lastName}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Last Name"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.lastName && <p className="text-red-500 text-sm">{formErrors.lastName}</p>}
//           </div>

//           {/* Email Field */}
//           <div>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Email"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>}
//           </div>

//           {/* Phone Field with Country Code selection */}
//           <div className="flex items-center relative">
//             <PhoneInput
//               country={'us'} // Default country
//               value={formData.phoneRaw} // Raw phone number with country code
//               onChange={handlePhoneInputChange}
//               inputClass="w-full border border-gray-300 rounded p-2 pr-12" // Add padding to the right for the tick mark
//               placeholder="Enter phone number"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formData.otpVerified && (
//               <FaCheckCircle className="absolute right-4 top-3 text-green-500" /> // Display tick mark on successful OTP verification
//             )}
//             {!formData.otpVerified && (
//               <button
//                 type="button"
//                 onClick={sendOtp}
//                 className="ml-4 bg-blue-500 text-white font-bold py-2 px-4 rounded"
//                 disabled={otpSent} // Disable the button once OTP is sent
//               >
//                 {otpSent ? 'OTP Sent' : 'Verify'}
//               </button>
//             )}
//           </div>
//           {formErrors.phone && <p className="text-red-500 text-sm">{formErrors.phone}</p>}
//           {otpError && <p className="text-red-500 text-sm">{otpError}</p>}

//           {/* OTP Input Field */}
//           {otpSent && !formData.otpVerified && (
//             <div>
//               <input
//                 type="text"
//                 name="otp"
//                 value={formData.otp}
//                 onChange={handleInputChange}
//                 className="w-full border border-gray-300 rounded p-2"
//                 placeholder="Enter OTP"
//               />
//               <button
//                 type="button"
//                 onClick={verifyOtp}
//                 className="mt-4 bg-green-500 text-white font-bold py-2 px-6 rounded"
//               >
//                 Verify OTP
//               </button>
//             </div>
//           )}

//           {/* Number of Adults */}
//           <div>
//             <input
//               type="number"
//               name="adults"
//               value={formData.adults}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Number of Adults"
//               min="1"
//               max="6"
//               disabled={!formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.adults && <p className="text-red-500 text-sm">{formErrors.adults}</p>}
//           </div>

//           {/* Number of Kids */}
//           <div>
//             <input
//               type="number"
//               name="kids"
//               value={formData.kids}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Number of Kids"
//               min="0"
//               max={6 - formData.adults}
//               disabled={!formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.kids && <p className="text-red-500 text-sm">{formErrors.kids}</p>}
//           </div>

//           {/* Amount Field */}
//           <div>
//             <input
//               type="number"
//               name="amount"
//               value={formData.amount}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Amount ($10 - $5000)"
//               min="10"
//               max="5000"
//               disabled={!formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.amount && <p className="text-red-500 text-sm">{formErrors.amount}</p>}
//           </div>

//           {/* Purchase Button */}
//           <button
//             type="button"
//             onClick={handleFormSubmit}
//             className={`bg-green-600 text-white font-bold py-2 px-6 rounded-full hover:bg-green-700 transition duration-300 ${
//               Object.keys(formErrors).length === 0 && formData.otpVerified ? '' : 'opacity-50 cursor-not-allowed'
//             }`}
//             disabled={Object.keys(formErrors).length !== 0 || !formData.otpVerified}
//           >
//             Proceed to Payment
//           </button>
//         </form>
//       </div>

//       {/* Disclaimer and other popups */}
//       {showDisclaimer && (
//         <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center overflow-hidden">
//           <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full h-[80vh] overflow-y-auto">
//             <h3 className="text-2xl font-bold text-center mb-6">Please Read and Accept the Terms and Conditions</h3>
//             <div className="text-sm space-y-2 text-justify">
//               <p>1. Attendees are responsible for ensuring their own safety and the safety of others. Event organizers are not liable for any accidents or injuries.</p>
//               <p>2. No outside food or beverages are allowed at the event.</p>
//               <p>3. Attendees must comply with all event rules and regulations.</p>
//               <p>4. The consumption of alcohol is strictly prohibited during the event.</p>
//               <p>5. The event organizers reserve the right to refuse entry or remove any attendee who does not comply with the rules.</p>
//               <p>6. Photography and video recording are allowed, but attendees must respect the privacy of others.</p>
//               <div className="flex items-center mt-4">
//                 <input
//                   type="checkbox"
//                   checked={acceptedTerms}
//                   onChange={() => setAcceptedTerms(!acceptedTerms)}
//                   className="mr-2"
//                 />
//                 <label>I agree to the terms and conditions</label>
//               </div>
//             </div>
//             <button
//               type="button"
//               onClick={handleProceed}
//               className={`mt-6 bg-green-600 text-white py-2 px-4 rounded-full hover:bg-green-700 transition duration-300 w-full ${
//                 acceptedTerms ? '' : 'opacity-50 cursor-not-allowed'
//               }`}
//               disabled={!acceptedTerms}
//             >
//               Proceed
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Card Details Popup */}
//       {showCardPopup && (
//         <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center overflow-hidden">
//           <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full h-[80vh] overflow-y-auto">
//             <h3 className="text-2xl font-bold text-center mb-6">Enter Payment Details</h3>
//             <CardElement className="border border-gray-300 p-4 rounded" />
//             <button
//               type="button"
//               onClick={handlePayment}
//               className="mt-6 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 w-full"
//             >
//               Pay and Complete Registration
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Loader while API calls are being made */}
//       {loading && (
//         <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50">
//           <div className="loader">Loading...</div>
//         </div>
//       )}

//       {/* Success Popup to display response details */}
//       {showPopup && (
//         <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
//           <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
//             <h3 className="text-2xl font-bold text-center mb-6">Registration Successful</h3>
//             <div className="space-y-2">
//               <p><strong>Name:</strong> {popupData.first_name} {popupData.last_name}</p>
//               <p><strong>Email:</strong> {popupData.email}</p>
//             </div>
//             <table className="w-full text-left mt-4 border-collapse">
//               <thead>
//                 <tr>
//                   <th className="border-b-2 py-2 font-semibold">Details</th>
//                   <th className="border-b-2 py-2 font-semibold">Amount</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className="py-2">Amount</td>
//                   <td className="py-2">${popupData.amount}</td>
//                 </tr>
//                 <tr>
//                   <td className="py-2">Transaction Type</td>
//                   <td className="py-2">{popupData.transaction_type}</td>
//                 </tr>
//               </tbody>
//             </table>
//             <div className="flex justify-center mt-6">
//               <img
//                 src={popupData.qr_code}
//                 alt="QR Code"
//                 className="w-32 h-32"
//               />
//             </div>
//             <button
//               onClick={() => setShowPopup(false)}
//               className="mt-6 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 w-full"
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default function RegisterWithStripe() {
//   return (
//     <Elements stripe={stripePromise}>
//       <Register />
//     </Elements>
//   );
// }


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid'; // Import the UUID library
// import { supabase } from '../supabaseClient'; // Import Supabase client
// import 'react-phone-input-2/lib/style.css'; // Import the CSS for the phone input component
// import PhoneInput from 'react-phone-input-2'; // Import the PhoneInput component
// import { FaCheckCircle } from 'react-icons/fa'; // Import the tick mark icon
// import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'; // Import Stripe elements
// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe('pk_test_51Ni6txCSxXRGUMtq79PZvPNYhKaypct1K24GVDQrrYOQCFR0QiC0gHanJI4fkzwmZ4jB5oPb1eH6foKaV9SlGFm1006k3sug3P'); // Replace with your Stripe public key

// const Register = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     phoneRaw: '', // Store the raw phone number (including country code)
//     otp: '', // New field for storing OTP
//     otpVerified: false, // Field to track if OTP is verified
//     adults: 1,
//     kids: 0,
//     amount: 10, // Default amount is $10
//     uuid: uuidv4(), // Generate a unique UUID for the user
//   });
//   const [formErrors, setFormErrors] = useState({});
//   const [popupData, setPopupData] = useState(null); // State for storing popup data
//   const [showPopup, setShowPopup] = useState(false); // State for controlling success popup visibility
//   const [showDisclaimer, setShowDisclaimer] = useState(false); // For showing disclaimer popup
//   const [showCardPopup, setShowCardPopup] = useState(false); // For showing card details popup
//   const [acceptedTerms, setAcceptedTerms] = useState(false); // For tracking if user accepted terms
//   const [loading, setLoading] = useState(false); // Loading state for API requests
//   const [otpSent, setOtpSent] = useState(false); // Track if OTP is sent
//   const [otpError, setOtpError] = useState(''); // Error message for OTP verification
//   const [clientSecret, setClientSecret] = useState(null); // Store client secret from Stripe

//   // Validation function for the form fields
//   const validateField = (name, value) => {
//     const errors = { ...formErrors };

//     switch (name) {
//       case 'firstName':
//         if (value.length < 4 || value.length > 30) {
//           errors.firstName = 'First Name must be between 4 and 30 characters';
//         } else {
//           delete errors.firstName;
//         }
//         break;
//       case 'lastName':
//         if (value.length < 4 || value.length > 30) {
//           errors.lastName = 'Last Name must be between 4 and 30 characters';
//         } else {
//           delete errors.lastName;
//         }
//         break;
//       case 'email':
//         const emailPattern = /^[^\s@]+\@[^\s@]+\.[^\s@]+$/;
//         if (!emailPattern.test(value)) {
//           errors.email = 'Invalid email address';
//         } else {
//           delete errors.email;
//         }
//         break;
//       case 'phoneRaw': // Validate the raw phone number (now includes country code)
//         if (!value || value.length < 10) {
//           errors.phone = 'Invalid phone number';
//         } else {
//           delete errors.phone;
//         }
//         break;
//       default:
//         break;
//     }

//     setFormErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//     validateField(name, value); // Revalidate the field when it changes
//   };

//   // Handle phone number input change (including country code)
//   const handlePhoneInputChange = (value) => {
//     setFormData({ ...formData, phoneRaw: value });
//     validateField('phoneRaw', value); // Validate the phone number
//   };

//   // Function to send OTP using Supabase (including country code)
//   const sendOtp = async () => {
//     setLoading(true);
//     const { error } = await supabase.auth.signInWithOtp({
//       phone: `+${formData.phoneRaw}`, // Send the phone number with the country code
//     });

//     if (error) {
//       setOtpError('Error sending OTP: ' + error.message);
//       setLoading(false);
//     } else {
//       setOtpSent(true);
//       setLoading(false);
//     }
//   };

//   // Function to verify OTP using Supabase
//   const verifyOtp = async () => {
//     const { error } = await supabase.auth.verifyOtp({
//       phone: `+${formData.phoneRaw}`, // Verify using the full phone number
//       token: formData.otp, // OTP entered by user
//       type: 'sms',
//     });

//     if (error) {
//       setOtpError('Invalid OTP. Please try again.');
//     } else {
//       setOtpError(''); // Clear any previous OTP errors
//       setFormData({ ...formData, otpVerified: true });
//     }
//   };

//   // Function to create a payment intent in your Supabase backend
//   const createPaymentIntent = async () => {
//     try {
//       const response = await axios.post(
//         'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/createPaymentIntent',
//         {
//           uuid: formData.uuid,
//           email: formData.email,
//           amount: formData.amount,
//         },
//         {
//           headers: {
//             Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`, // Replace with your JWT token
//           },
//         }
//       );
//       setClientSecret(response.data.client_secret); // Store the client secret returned from the backend
//     } catch (error) {
//       console.error('Error creating payment intent:', error);
//     }
//   };

//   const handleFormSubmit = async () => {
//     if (Object.keys(formErrors).length === 0 && formData.otpVerified) {
//       setShowDisclaimer(true);
//     }
//   };

//   // Close disclaimer popup, open card details popup
//   const handleProceed = async () => {
//     setShowDisclaimer(false); // Close T&C popup
//     setShowCardPopup(true); // Open card details popup
//   };

//   // Trigger the payment, then registration API and updateStatus API
//   const handlePayment = async () => {
//     setLoading(true);
//     try {
//       // Step 1: Create a payment intent
//       await createPaymentIntent();

//       // Step 2: Handle the payment process
//       const cardElement = elements.getElement(CardElement);
//       const paymentResult = await stripe.confirmCardPayment(clientSecret, {
//         payment_method: {
//           card: cardElement,
//           billing_details: {
//             name: `${formData.firstName} ${formData.lastName}`,
//             email: formData.email,
//           },
//         },
//       });

//       if (paymentResult.error) {
//         console.error('Error during payment confirmation:', paymentResult.error.message);
//         setLoading(false);
//       } else if (paymentResult.paymentIntent.status === 'succeeded') {
//         // Step 3: Trigger the registration API after successful payment
//         const registrationResponse = await axios.post(
//           'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/registration',
//           {
//             uuid: formData.uuid,
//             first_name: formData.firstName,
//             last_name: formData.lastName,
//             email: formData.email,
//             phone_no: formData.phoneRaw,
//             no_adults: formData.adults,
//             no_kids: formData.kids,
//             amount: formData.amount,
//             transaction_type: "Card",
//           },
//           {
//             headers: {
//               Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`,
//             },
//           }
//         );

//         const insertedData = registrationResponse.data.data;
//         const uuid = insertedData[0].uuid;

//         // Step 4: Trigger the updateStatus API
//         const updateStatusResponse = await axios.post(
//           'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/updateStatus',
//           {
//             uuid: uuid,
//             transaction_status: 'success',
//           },
//           {
//             headers: {
//               Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`,
//             },
//           }
//         );

//         setPopupData(updateStatusResponse.data);
//         setShowCardPopup(false); // Close card details popup
//         setShowPopup(true); // Show success popup
//         setLoading(false);
//       }
//     } catch (error) {
//       console.error('Error in registration or payment:', error);
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="w-11/12 mx-auto bg-gray-50 flex flex-col justify-center items-center p-8" style={{ minHeight: '100vh', overflow: 'auto' }}>
//       <div className="w-full md:w-1/2 max-h-[80vh] overflow-y-auto">
//         <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">Vana Bhojanalu Registration</h2>
//         <form className="space-y-4">
//           {/* First Name Field */}
//           <div>
//             <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
//               First Name
//             </label>
//             <input
//               type="text"
//               name="firstName"
//               value={formData.firstName}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="First Name"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.firstName && <p className="text-red-500 text-sm">{formErrors.firstName}</p>}
//           </div>

//           {/* Last Name Field */}
//           <div>
//             <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
//               Last Name
//             </label>
//             <input
//               type="text"
//               name="lastName"
//               value={formData.lastName}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Last Name"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.lastName && <p className="text-red-500 text-sm">{formErrors.lastName}</p>}
//           </div>

//           {/* Email Field */}
//           <div>
//             <label htmlFor="email" className="block text-sm font-medium text-gray-700">
//               Email
//             </label>
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Email"
//               disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>}
//           </div>

//           {/* Phone Field with Country Code selection */}
//           <div className="relative">
//             <label htmlFor="phoneRaw" className="block text-sm font-medium text-gray-700">
//               Phone Number
//             </label>
//             <div className="flex items-center w-full space-x-2">
//               <div className="flex-grow">
//                 <PhoneInput
//                   country={'us'} // Default country to US
//                   onlyCountries={['us']} // Limit to only US numbers
//                   disableDropdown={true} // Disable the country dropdown
//                   value={formData.phoneRaw} // Raw phone number with country code
//                   onChange={handlePhoneInputChange}
//                   inputClass="w-full border border-gray-300 rounded p-2" // Adjusts dynamically based on available space
//                   placeholder="Enter phone number"
//                   disabled={otpSent && !formData.otpVerified} // Disable field until OTP is verified
//                 />
//               </div>
//               {!formData.otpVerified && (
//                 <button
//                   type="button"
//                   onClick={sendOtp}
//                   className="bg-blue-500 text-white font-bold py-2 px-4 rounded w-auto"
//                   disabled={otpSent} // Disable the button once OTP is sent
//                 >
//                   {otpSent ? 'OTP Sent' : 'Verify'}
//                 </button>
//               )}
//             </div>

//             {formErrors.phone && <p className="text-red-500 text-sm">{formErrors.phone}</p>}
//             {otpError && <p className="text-red-500 text-sm">{otpError}</p>}
//           </div>

//           {/* OTP Input Field */}
//           {otpSent && !formData.otpVerified && (
//             <div>
//               <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
//                 Enter OTP
//               </label>
//               <input
//                 type="text"
//                 name="otp"
//                 value={formData.otp}
//                 onChange={handleInputChange}
//                 className="w-full border border-gray-300 rounded p-2"
//                 placeholder="Enter OTP"
//               />
//               <button
//                 type="button"
//                 onClick={verifyOtp}
//                 className="mt-4 bg-green-500 text-white font-bold py-2 px-6 rounded"
//               >
//                 Verify OTP
//               </button>
//             </div>
//           )}

//           {/* Number of Adults */}
//           <div>
//             <label htmlFor="adults" className="block text-sm font-medium text-gray-700">
//               Number of Adults
//             </label>
//             <input
//               type="number"
//               name="adults"
//               value={formData.adults}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Number of Adults"
//               min="1"
//               max="6"
//               disabled={!formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.adults && <p className="text-red-500 text-sm">{formErrors.adults}</p>}
//           </div>

//           {/* Number of Kids */}
//           <div>
//             <label htmlFor="kids" className="block text-sm font-medium text-gray-700">
//               Number of Kids
//             </label>
//             <input
//               type="number"
//               name="kids"
//               value={formData.kids}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Number of Kids"
//               min="0"
//               max={6 - formData.adults}
//               disabled={!formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.kids && <p className="text-red-500 text-sm">{formErrors.kids}</p>}
//           </div>

//           {/* Amount Field */}
//           <div>
//             <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
//               Amount ($10 - $5000)
//             </label>
//             <input
//               type="number"
//               name="amount"
//               value={formData.amount}
//               onChange={handleInputChange}
//               className="w-full border border-gray-300 rounded p-2"
//               placeholder="Amount ($10 - $5000)"
//               min="10"
//               max="5000"
//               disabled={!formData.otpVerified} // Disable field until OTP is verified
//             />
//             {formErrors.amount && <p className="text-red-500 text-sm">{formErrors.amount}</p>}
//           </div>

//           {/* Proceed to Payment Button */}
//           <button
//             type="button"
//             onClick={handleFormSubmit}
//             className={`bg-green-600 text-white font-bold py-2 px-6 rounded-full hover:bg-green-700 transition duration-300 ${
//               Object.keys(formErrors).length === 0 && formData.otpVerified ? '' : 'opacity-50 cursor-not-allowed'
//             }`}
//             disabled={Object.keys(formErrors).length !== 0 || !formData.otpVerified}
//           >
//             Proceed to Payment
//           </button>
//         </form>
//       </div>

//       {/* Disclaimer and other popups */}
//       {showDisclaimer && (
//         <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center overflow-hidden">
//           <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full h-[80vh] overflow-y-auto">
//             <h3 className="text-2xl font-bold text-center mb-6">Please Read and Accept the Terms and Conditions</h3>
//             <div className="text-sm space-y-2 text-justify">
//               <p>1. Attendees are responsible for ensuring their own safety and the safety of others. Event organizers are not liable for any accidents or injuries.</p>
//               <p>2. No outside food or beverages are allowed at the event.</p>
//               <p>3. Attendees must comply with all event rules and regulations.</p>
//               <p>4. The consumption of alcohol is strictly prohibited during the event.</p>
//               <p>5. The event organizers reserve the right to refuse entry or remove any attendee who does not comply with the rules.</p>
//               <p>6. Photography and video recording are allowed, but attendees must respect the privacy of others.</p>
//               <div className="flex items-center mt-4">
//                 <input
//                   type="checkbox"
//                   checked={acceptedTerms}
//                   onChange={() => setAcceptedTerms(!acceptedTerms)}
//                   className="mr-2"
//                 />
//                 <label>I agree to the terms and conditions</label>
//               </div>
//             </div>
//             <button
//               type="button"
//               onClick={handleProceed}
//               className={`mt-6 bg-green-600 text-white py-2 px-4 rounded-full hover:bg-green-700 transition duration-300 w-full ${
//                 acceptedTerms ? '' : 'opacity-50 cursor-not-allowed'
//               }`}
//               disabled={!acceptedTerms}
//             >
//               Proceed
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Card Details Popup */}
//       {showCardPopup && (
//         <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center overflow-hidden">
//           <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full h-[80vh] overflow-y-auto">
//             <h3 className="text-2xl font-bold text-center mb-6">Enter Payment Details</h3>
//             <CardElement className="border border-gray-300 p-4 rounded" />
//             <button
//               type="button"
//               onClick={handlePayment}
//               className="mt-6 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 w-full"
//             >
//               Pay and Complete Registration
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Loader while API calls are being made */}
//       {loading && (
//         <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50">
//           <div className="loader">Loading...</div>
//         </div>
//       )}

//       {/* Success Popup to display response details */}
//       {showPopup && (
//         <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
//           <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
//             <h3 className="text-2xl font-bold text-center mb-6">Registration Successful</h3>
//             <div className="space-y-2">
//               <p><strong>Name:</strong> {popupData.first_name} {popupData.last_name}</p>
//               <p><strong>Email:</strong> {popupData.email}</p>
//             </div>
//             <table className="w-full text-left mt-4 border-collapse">
//               <thead>
//                 <tr>
//                   <th className="border-b-2 py-2 font-semibold">Details</th>
//                   <th className="border-b-2 py-2 font-semibold">Amount</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td className="py-2">Amount</td>
//                   <td className="py-2">${popupData.amount}</td>
//                 </tr>
//                 <tr>
//                   <td className="py-2">Transaction Type</td>
//                   <td className="py-2">{popupData.transaction_type}</td>
//                 </tr>
//               </tbody>
//             </table>
//             <div className="flex justify-center mt-6">
//               <img
//                 src={popupData.qr_code}
//                 alt="QR Code"
//                 className="w-32 h-32"
//               />
//             </div>
//             <button
//               onClick={() => setShowPopup(false)}
//               className="mt-6 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 w-full"
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default function RegisterWithStripe() {
//   return (
//     <Elements stripe={stripePromise}>
//       <Register />
//     </Elements>
//   );
// }

import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../supabaseClient';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51Ni6txCSxXRGUMtqZQncc1Jtlx53hfGP9YZzZ6hnaxXmyMFvuwBEAhSBryVKtuTkb8lcfTEqpVyx2BP2EZU3Crrh00Sy1AyDm5');

const Register = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneRaw: '',
    otp: '',
    otpVerified: false,
    adults: 1,
    kids: 0,
    amount: 0, // Default amount set to 0
    uuid: uuidv4(),
  });
  const [formErrors, setFormErrors] = useState({});
  const [popupData, setPopupData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false); // Disclaimer popup state
  const [acceptedTerms, setAcceptedTerms] = useState(false); // Terms acceptance state
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [paymentError, setPaymentError] = useState(''); // Track payment errors
  const [showPaymentFailure, setShowPaymentFailure] = useState(false); // Payment failure state
  const [clientSecret, setClientSecret] = useState(null); // Store client secret from Stripe

  const JWT_TOKEN = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1tcGp3c2l5Z2VzemhiaGh0dmNlIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgyMDcyNjMsImV4cCI6MjA0Mzc4MzI2M30.dNKUJ01zfpxj-WJLlXucUp0loMRYUQ1BJSpZnaCiFHk`;

  const validateField = (name, value) => {
    const errors = { ...formErrors };

    switch (name) {
      case 'firstName':
        if (value.length < 4 || value.length > 30) {
          errors.firstName = 'First Name must be between 4 and 30 characters';
        } else {
          delete errors.firstName;
        }
        break;
      case 'lastName':
        if (value.length < 4 || value.length > 30) {
          errors.lastName = 'Last Name must be between 4 and 30 characters';
        } else {
          delete errors.lastName;
        }
        break;
      case 'email':
        const emailPattern = /^[^\s@]+\@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) {
          errors.email = 'Invalid email address';
        } else {
          delete errors.email;
        }
        break;
      case 'phoneRaw':
        if (!value || value.length < 10) {
          errors.phone = 'Invalid phone number';
        } else {
          delete errors.phone;
        }
        break;
      default:
        break;
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateTotalPeople = () => {
    const totalPeople = parseInt(formData.adults) + parseInt(formData.kids);
    if (totalPeople > 6) {
      setFormErrors({
        ...formErrors,
        people: 'Total number of people (adults + kids) cannot exceed 6',
      });
    } else {
      const { people, ...remainingErrors } = formErrors;
      setFormErrors(remainingErrors); // Remove the "people" error if valid
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);

    if (name === 'adults' || name === 'kids') {
      validateTotalPeople(); // Check total people after each update
    }
  };

  const handlePhoneInputChange = (value) => {
    setFormData({ ...formData, phoneRaw: value });
    validateField('phoneRaw', value);
  };

  const sendOtp = async () => {
    setLoading(true);
    const { error } = await supabase.auth.signInWithOtp({
      phone: `+${formData.phoneRaw}`,
    });

    if (error) {
      setOtpError('Error sending OTP: ' + error.message);
      setLoading(false);
    } else {
      setOtpSent(true);
      setLoading(false);
    }
  };

  const verifyOtp = async () => {
    const { error } = await supabase.auth.verifyOtp({
      phone: `+${formData.phoneRaw}`,
      token: formData.otp,
      type: 'sms',
    });

    if (error) {
      setOtpError('Invalid OTP. Please try again.');
    } else {
      setOtpError('');
      setFormData({ ...formData, otpVerified: true });
    }
  };

  const triggerRegistration = async () => {
    try {
      const registrationResponse = await axios.post(
        'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/registration',
        {
          uuid: formData.uuid,
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          phone_no: formData.phoneRaw,
          no_adults: formData.adults,
          no_kids: formData.kids,
          amount: formData.amount,
          transaction_type: 'Donation',
        },
        {
          headers: {
            Authorization: JWT_TOKEN, // Using your existing JWT token
          },
        }
      );

      const insertedData = registrationResponse.data.data;
      const uuid = insertedData[0].uuid;

      await triggerUpdateStatus(uuid);
    } catch (error) {
      console.error('Error in registration:', error);
    }
  };

  const triggerUpdateStatus = async (uuid) => {
    try {
      const updateStatusResponse = await axios.post(
        'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/updateStatus',
        {
          uuid: uuid,
          transaction_status: 'success',
        },
        {
          headers: {
            Authorization: JWT_TOKEN, // Using your existing JWT token
          },
        }
      );
      setPopupData(updateStatusResponse.data);
      setShowPopup(true); // Show the success popup after update status
    } catch (error) {
      console.error('Error in update status:', error);
    }
  };

  const createPaymentIntent = async () => {
    try {
      const response = await axios.post(
        'https://mmpjwsiygeszhbhhtvce.supabase.co/functions/v1/createPaymentIntent',
        {
          uuid: formData.uuid,
          email: formData.email,
          amount: formData.amount,
        },
        {
          headers: {
            Authorization: JWT_TOKEN,
          },
        }
      );
      return response.data.client_secret; // Return the client secret
    } catch (error) {
      console.error('Error creating payment intent:', error);
      return null;
    }
  };

  const handlePayment = async () => {
    setLoading(true);
    const clientSecret = await createPaymentIntent(); // Ensure we wait for clientSecret

    if (!clientSecret) {
      setPaymentError('Payment could not be initiated. Please try again.');
      setShowPaymentFailure(true);
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${formData.firstName} ${formData.lastName}`,
            email: formData.email,
          },
        },
      });

      if (paymentResult.error) {
        setPaymentError(paymentResult.error.message);
        setShowPaymentFailure(true);
      } else if (paymentResult.paymentIntent.status === 'succeeded') {
        setShowDisclaimer(true); // Show disclaimer popup on successful payment
      }
    } catch (error) {
      setPaymentError('Payment failed. Please try again.');
      setShowPaymentFailure(true);
    }
    setLoading(false);
  };

  const handleFormSubmit = async () => {
    const totalPeople = parseInt(formData.adults) + parseInt(formData.kids);

    if (totalPeople > 6) {
      setFormErrors({ ...formErrors, people: 'Total number of people (adults + kids) cannot exceed 6' });
      return;
    }

    if (Object.keys(formErrors).length === 0 && formData.otpVerified) {
      if (formData.amount > 0) {
        // Trigger payment if amount is greater than 0
        handlePayment();
      } else {
        setShowDisclaimer(true); // Show disclaimer popup if no payment needed
      }
    }
  };

  const handleProceed = () => {
    setShowDisclaimer(false); // Close the disclaimer popup
    triggerRegistration(); // Trigger the registration process after disclaimer acceptance
  };

  return (
    <div className="w-11/12 mx-auto bg-gray-50 flex flex-col justify-center items-center p-8" style={{ minHeight: '100vh', overflow: 'auto' }}>
      <div className="w-full md:w-1/2 max-h-[80vh] overflow-y-auto">
        <h2 className="text-3xl font-bold text-gray-900 text-center mb-6">Vana Bhojanalu Registration</h2>
        <form className="space-y-4">
          {/* First Name Field */}
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-2"
              placeholder="First Name"
              disabled={otpSent && !formData.otpVerified}
            />
            {formErrors.firstName && <p className="text-red-500 text-sm">{formErrors.firstName}</p>}
          </div>

          {/* Last Name Field */}
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Last Name"
              disabled={otpSent && !formData.otpVerified}
            />
            {formErrors.lastName && <p className="text-red-500 text-sm">{formErrors.lastName}</p>}
          </div>

          {/* Email Field */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Email"
              disabled={otpSent && !formData.otpVerified}
            />
            {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>}
          </div>

          {/* Phone Field with Country Code selection */}
          <div className="relative">
            <label htmlFor="phoneRaw" className="block text-sm font-medium text-gray-700">
              Phone Number
            </label>
            <div className="flex flex-col sm:flex-row sm:items-center w-full space-y-2 sm:space-y-0 sm:space-x-2">
              <div className="flex-grow">
                <PhoneInput
                  country={'us'} // Default country to US
                  onlyCountries={['us']} // Limit to only US numbers
                  disableDropdown={true} // Disable the country dropdown
                  value={formData.phoneRaw}
                  onChange={handlePhoneInputChange}
                  inputClass="w-full border border-gray-300 rounded p-2"
                  placeholder="Enter phone number"
                  disabled={otpSent && !formData.otpVerified}
                />
              </div>
              {!formData.otpVerified && (
                <button
                  type="button"
                  onClick={sendOtp}
                  className="bg-blue-500 text-white font-bold py-2 px-4 rounded w-auto"
                  disabled={otpSent}
                >
                  {otpSent ? 'OTP Sent' : 'Verify'}
                </button>
              )}
            </div>

            {formErrors.phone && <p className="text-red-500 text-sm">{formErrors.phone}</p>}
            {otpError && <p className="text-red-500 text-sm">{otpError}</p>}
          </div>

          {/* OTP Input Field */}
          {otpSent && !formData.otpVerified && (
            <div>
              <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
                Enter OTP
              </label>
              <input
                type="text"
                name="otp"
                value={formData.otp}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded p-2"
                placeholder="Enter OTP"
              />
              <button
                type="button"
                onClick={verifyOtp}
                className="mt-4 bg-green-500 text-white font-bold py-2 px-6 rounded"
              >
                Verify OTP
              </button>
            </div>
          )}

          {/* Number of Adults */}
          <div>
            <label htmlFor="adults" className="block text-sm font-medium text-gray-700">
              Number of Adults
            </label>
            <input
              type="number"
              name="adults"
              value={formData.adults}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Number of Adults"
              min="1"
              max="6"
              disabled={!formData.otpVerified}
            />
            {formErrors.adults && <p className="text-red-500 text-sm">{formErrors.adults}</p>}
          </div>

          {/* Number of Kids */}
          <div>
            <label htmlFor="kids" className="block text-sm font-medium text-gray-700">
              Number of Kids
            </label>
            <input
              type="number"
              name="kids"
              value={formData.kids}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Number of Kids"
              min="0"
              max={6 - formData.adults}
              disabled={!formData.otpVerified}
            />
            {formErrors.kids && <p className="text-red-500 text-sm">{formErrors.kids}</p>}
          </div>

          {/* Error for total people */}
          {formErrors.people && <p className="text-red-500 text-sm">{formErrors.people}</p>}

          {/* Donation Amount Field */}
          <div>
            <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
              Donation Amount (0 - $5000)
            </label>
            <input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Donation Amount (0 - $5000)"
              min="0"
              max="5000"
              disabled={!formData.otpVerified}
            />
            {formErrors.amount && <p className="text-red-500 text-sm">{formErrors.amount}</p>}
          </div>

          {/* Stripe Payment Section */}
          {formData.amount > 0 && (
            <div>
              <h3 className="text-lg font-medium text-gray-700">Enter your payment details</h3>
              <CardElement className="w-full border border-gray-300 rounded p-2" />
            </div>
          )}

          {/* Proceed to Payment Button */}
          <button
            type="button"
            onClick={handleFormSubmit}
            className={`bg-green-600 text-white font-bold py-2 px-6 rounded-full hover:bg-green-700 transition duration-300 ${
              Object.keys(formErrors).length === 0 && formData.otpVerified ? '' : 'opacity-50 cursor-not-allowed'
            }`}
            disabled={Object.keys(formErrors).length !== 0 || !formData.otpVerified}
          >
            Donation Amount
          </button>
        </form>
      </div>

      {/* Disclaimer Popup */}
      {showDisclaimer && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center overflow-hidden">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full h-[80vh] overflow-y-auto">
            <h3 className="text-2xl font-bold text-center mb-6">
              Please Read and Accept the Terms and Conditions
            </h3>
            <div className="text-sm space-y-2 text-justify">
            <p>1. Attendees are hereby informed that foods prepared at this event may contain or have come into contact with common allergens, including but not limited to milk, eggs, wheat, soybean, peanuts, tree nuts, fish, lupin, mollusks, mustard, sesame, celery, sulfur dioxide, and shellfish. It is the responsibility of each attendee to inquire about the ingredients and allergen risks before consuming any food at the event to ensure their safety.</p>
            <p>2. The consumption or sale of alcohol at the event is strictly prohibited. Any violation of this rule will result in immediate removal from the premises without any refund or further recourse. In addition, political demonstrations, flag displays, shouting, or any form of political expression are not allowed, as we aim to maintain a neutral and inclusive atmosphere for all participants. The use, possession, or display of fireworks is strictly forbidden, and any violators of this rule will be removed from the event and may face legal action for safety violations.</p>
          <p>3. All attendees are solely responsible for the safety and security of their personal belongings. The event organizers and the Kakatiya Seva Samithi will not be held liable for any loss, theft, or damage to personal items or property during the event.</p>
          <p>4. We expect all attendees to treat volunteers, staff, and fellow guests with respect. Any arguments, fights, or aggressive behavior toward others will not be tolerated and will result in immediate expulsion from the event. Additionally, all decisions regarding the event’s style, format, structure, or any changes will be made by the organizing committee. Attendees are expected to respect these decisions without questioning or challenging any modifications.</p>
          <p>5. While we strive to create a safe and enjoyable environment, our liability is limited to incidents arising directly from our negligence or intentional misconduct. We will not be liable for any injury, illness, death, property loss or damage, or other harm suffered by attendees unless it is directly attributable to our actions. We also disclaim responsibility for emotional distress, mental suffering, or psychological injury, except in cases where such distress results from physical harm due to our negligence or willful misconduct, or when the injury was intentionally inflicted by organizers, volunteers, staff, contractors, or representatives. Under no circumstances will we be liable for any consequential, incidental, exemplary, or punitive damages.</p>
          <p>6. In the event of unforeseen circumstances beyond our control, such as natural disasters, acts of terrorism, war, government restrictions, strikes, pandemics, or other force majeure events, we cannot be held liable for any disruptions or cancellations. These events may make it impossible to fulfill our obligations as planned.</p>
          <p>7. Personal data collected during the registration process will be handled with the utmost confidentiality. We assure all participants that their personal information will not be shared with any third parties, organizations, or used for any marketing purposes. Data will solely be used for event management and to ensure the safety and organization of the event.</p>
          <p>8. Only registered attendees will be permitted entry to the event. The number of guests allowed is strictly as per the details provided in the registration. Our volunteers will verify the admission passes upon arrival, and any individual without proper registration will not be allowed entry. No exceptions will be made.</p>
          <p>9. By attending this event, participants acknowledge that there are inherent risks involved, including potential exposure to COVID-19, other diseases, bacteria, viruses, or pathogens. Attendees voluntarily assume all risks, which may include illness, injury, permanent disability, or death. By attending, participants waive any right to hold the organizers liable for any injury, illness, death, or property damage, whether or not related to the event or its associated services. This waiver also extends to any guests or invitees.</p>
          <p>10. We reserve the right to deny entry or remove any individual from the event for reasons including, but not limited to, disruptive behavior, safety concerns, non-compliance with security procedures, or violation of these terms. This includes individuals under the influence of prohibited substances, engaging in disorderly conduct, or failing to provide identification or proof of age when requested.</p>
          <p>11. Your attendance at the event signifies your understanding and acceptance of these terms and conditions. Failure to comply with these rules may result in removal without compensation or liability on our part.</p>
         {/* Add more disclaimer text here */}
              <div className="flex items-center mt-4">
                <input
                  type="checkbox"
                  checked={acceptedTerms}
                  onChange={() => setAcceptedTerms(!acceptedTerms)}
                  className="mr-2"
                />
                <label>I agree to the terms and conditions</label>
              </div>
            </div>
            <button
              type="button"
              onClick={handleProceed}
              className={`mt-6 bg-green-600 text-white py-2 px-4 rounded-full hover:bg-green-700 transition duration-300 w-full ${
                acceptedTerms ? '' : 'opacity-50 cursor-not-allowed'
              }`}
              disabled={!acceptedTerms}
            >
              Proceed
            </button>
          </div>
        </div>
      )}

      {/* Success Popup */}
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
            <h3 className="text-2xl font-bold text-center mb-6">Registration Successful</h3>
            <div className="space-y-2">
              <p><strong>Name:</strong> {popupData.first_name} {popupData.last_name}</p>
              <p><strong>Email:</strong> {popupData.email}</p>
            </div>
            <table className="w-full text-left mt-4 border-collapse">
              <thead>
                <tr>
                  <th className="border-b-2 py-2 font-semibold">Details</th>
                  <th className="border-b-2 py-2 font-semibold">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-2">Amount</td>
                  <td className="py-2">${popupData.amount}</td>
                </tr>
                <tr>
                  <td className="py-2">Transaction Type</td>
                  <td className="py-2">{popupData.transaction_type}</td>
                </tr>
              </tbody>
            </table>
            <div className="flex justify-center mt-6">
               <img
                 src={popupData.qr_code}
                alt="QR Code"
                 className="w-32 h-32"
               />
             </div>
            <button
              onClick={() => setShowPopup(false)}
              className="mt-6 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Payment Failure Popup */}
      {showPaymentFailure && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
            <h3 className="text-2xl font-bold text-center mb-6">Payment Failed</h3>
            <p className="text-red-600 text-center">{paymentError}</p>
            <button
              onClick={() => setShowPaymentFailure(false)}
              className="mt-6 bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default function RegisterWithStripe() {
  return (
    <Elements stripe={stripePromise}>
      <Register />
    </Elements>
  );
}
