import React, { useState, useEffect } from 'react';
import backgroundImage from '../assets/background.jpg'; // Import the background image

const Home = () => {
  const [isPageVisible, setIsPageVisible] = useState(false); // State to trigger the fade-in transition

  // Trigger fade-in after page load
  useEffect(() => {
    setTimeout(() => {
      setIsPageVisible(true); // Trigger fade-in
    }, 500); // Delay the animation slightly after page load
  }, []);

  return (
    <div
      className={`w-11/12 mx-auto rounded-t-3xl overflow-hidden flex flex-col justify-center items-center transition-opacity duration-1000 relative ${
        isPageVisible ? 'opacity-100' : 'opacity-0'
      }`}
      style={{ height: 'calc(100vh - 6rem)' }} // Adjusting height based on the header's height (6rem)
    >
      {/* Background Image with Blur Effect */}
      <div
        className="absolute inset-0 bg-cover bg-white bg-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          filter: 'blur(5px)', // Apply a slight blur to the background
          zIndex: '-1', // Ensure the background stays behind the text
        }}
      ></div>

      {/* Centered Text */}
      <h1 className="text-4xl md:text-9xl text-center font-bold text-gray-900 mb-4 font-poppins z-10">
        Welcome to
      </h1>
      <h2 className="text-4xl md:text-8xl text-center font-bold text-blue-600 font-poppins z-10">
        2024
      </h2>
      <h2 className="text-4xl md:text-8xl text-center font-bold text-blue-600 font-poppins z-10">
        Kakatiya Vana Bhojanalu
      </h2>

      {/* Scroll Down Mouse Icon and Text */}
      <div className="absolute bottom-8 flex flex-col items-center z-10">
        {/* Mouse Icon */}
        <div className="w-6 h-12 border-2 border-white rounded-full flex justify-center items-start">
          <div className="w-2 h-2 bg-white rounded-full animate-bounce mt-1"></div>
        </div>
        {/* Scroll Down Text */}
        <p className="text-white text-sm mt-2 font-bold">Scroll Down</p>
      </div>
    </div>
  );
};

export default Home;
