// import React from 'react';
// import Header from './components/Header';
// import Home from './pages/Home';
// import Why from './pages/Why';

// function App() {
//   return (
//     <div className="App relative">
//       <Header />
//       <Home />
//       <Why />
//     </div>
//   );
// }

// export default App;



// import React, { useState, useEffect } from 'react';
// import Header from './components/Header';
// import Home from './pages/Home';
// import Why from './pages/Why';
// import Goals from './pages/Goals';
// import './App.css'

// function App() {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const slides = [<Home />, <Why />, <Goals />];

//   useEffect(() => {
//     const handleScroll = (event) => {
//       if (event.deltaY > 0 && currentSlide < slides.length - 1) {
//         setCurrentSlide(currentSlide + 1);
//       } else if (event.deltaY < 0 && currentSlide > 0) {
//         setCurrentSlide(currentSlide - 1);
//       }
//     };

//     window.addEventListener('wheel', handleScroll);

//     return () => {
//       window.removeEventListener('wheel', handleScroll);
//     };
//   }, [currentSlide, slides.length]);

//   return (
//     <div className="App">
//       <Header />
//       <div className="slides-container">
//         {slides.map((SlideComponent, index) => (
//           <div
//             key={index}
//             className={`slide ${index === currentSlide ? 'active' : ''}`}
//             style={{
//               transform: `translateY(${index <= currentSlide ? '0' : '100%'})`,
//               zIndex: index === currentSlide ? 2 : 1,
//             }}
//           >
//             {SlideComponent}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default App;


// import React, { useState, useEffect } from 'react';
// import Header from './components/Header';
// import Home from './pages/Home';
// import Why from './pages/Why';
// import Goals from './pages/Goals';
// import Services from './pages/Services';
// import Register from './pages/Register';
// import './App.css';

// function App() {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const slides = [<Home />, <Why />, <Services />, <Goals />];
//   const [isThrottled, setIsThrottled] = useState(false);

//   useEffect(() => {
//     const handleScroll = (event) => {
//       if (isThrottled) return;

//       if (event.deltaY > 0 && currentSlide < slides.length - 1) {
//         setCurrentSlide((prevSlide) => prevSlide + 1);
//       } else if (event.deltaY < 0 && currentSlide > 0) {
//         setCurrentSlide((prevSlide) => prevSlide - 1);
//       }

//       setIsThrottled(true);
//       setTimeout(() => {
//         setIsThrottled(false);
//       }, 800); // Adjust the delay (in milliseconds) as needed
//     };

//     window.addEventListener('wheel', handleScroll);

//     return () => {
//       window.removeEventListener('wheel', handleScroll);
//     };
//   }, [currentSlide, isThrottled, slides.length]);

//   return (
//     <div className="App">
//       <Header />
//       <div className="slides-container">
//         {slides.map((SlideComponent, index) => (
//           <div
//             key={index}
//             className={`slide ${index === currentSlide ? 'active' : ''}`}
//             style={{
//               transform: `translateY(${index <= currentSlide ? '0' : '100%'})`,
//               zIndex: index === currentSlide ? 2 : 1,
//             }}
//           >
//             {SlideComponent}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default App;

// import React, { useState, useEffect } from 'react';
// import Header from './components/Header';
// import Home from './pages/Home';
// import Why from './pages/Why';
// import Goals from './pages/Goals';
// import Services from './pages/Services';
// import Register from './pages/Register';
// import './App.css';
// import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

// function App() {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const slides = [<Home />, <Why />, <Services />, <Goals />];
//   const [isThrottled, setIsThrottled] = useState(false);
//   const location = useLocation(); // React Router location

//   useEffect(() => {
//     if (location.pathname !== "/register") {
//       const handleScroll = (event) => {
//         if (isThrottled) return;

//         if (event.deltaY > 0 && currentSlide < slides.length - 1) {
//           setCurrentSlide((prevSlide) => prevSlide + 1);
//         } else if (event.deltaY < 0 && currentSlide > 0) {
//           setCurrentSlide((prevSlide) => prevSlide - 1);
//         }

//         setIsThrottled(true);
//         setTimeout(() => {
//           setIsThrottled(false);
//         }, 800); // Adjust the delay (in milliseconds) as needed
//       };

//       window.addEventListener('wheel', handleScroll);

//       return () => {
//         window.removeEventListener('wheel', handleScroll);
//       };
//     }
//   }, [currentSlide, isThrottled, slides.length, location.pathname]);

//   return (
//     <div className="App">
//       <Header />
//       <Routes>
//         {/* Slides section */}
//         <Route
//           path="/"
//           element={
//             <div className="slides-container">
//               {slides.map((SlideComponent, index) => (
//                 <div
//                   key={index}
//                   className={`slide ${index === currentSlide ? 'active' : ''}`}
//                   style={{
//                     transform: `translateY(${index <= currentSlide ? '0' : '100%'})`,
//                     zIndex: index === currentSlide ? 2 : 1,
//                   }}
//                 >
//                   {SlideComponent}
//                 </div>
//               ))}
//             </div>
//           }
//         />
//         {/* Regular Register page without slides-container class */}
//         <Route path="/register" element={<Register />} />
//       </Routes>
//     </div>
//   );
// }

// export default function AppWrapper() {
//   return (
//     <Router>
//       <App />
//     </Router>
//   );
// }


// import React, { useState, useEffect } from 'react';
// import Header from './components/Header';
// import Home from './pages/Home';
// import Why from './pages/Why';
// import Goals from './pages/Goals';
// import Services from './pages/Services';
// import Register from './pages/Register';

// import './App.css';
// import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

// function App() {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const slides = [<Goals />, <Home />, <Why />, <Services />, <Goals />];
//   const [isThrottled, setIsThrottled] = useState(false);
//   const location = useLocation(); // React Router location

//   useEffect(() => {
//     if (location.pathname !== "/register") {
//       const handleScroll = (event) => {
//         if (isThrottled) return;

//         if (event.deltaY > 0 && currentSlide < slides.length - 1) {
//           setCurrentSlide((prevSlide) => prevSlide + 1);
//         } else if (event.deltaY < 0 && currentSlide > 0) {
//           setCurrentSlide((prevSlide) => prevSlide - 1);
//         }

//         setIsThrottled(true);
//         setTimeout(() => {
//           setIsThrottled(false);
//         }, 800); // Adjust the delay (in milliseconds) as needed
//       };

//       window.addEventListener('wheel', handleScroll);

//       return () => {
//         window.removeEventListener('wheel', handleScroll);
//       };
//     }
//   }, [currentSlide, isThrottled, slides.length, location.pathname]);

//   return (
//     <div className={`App ${location.pathname === '/register' ? 'no-slide' : ''}`}>
//       <Header />
//       <Routes>
//         {/* Slides section */}
//         <Route
//           path="/"
//           element={
//             <div className="slides-container">
//               {slides.map((SlideComponent, index) => (
//                 <div
//                   key={index}
//                   className={`slide ${index === currentSlide ? 'active' : ''}`}
//                   style={{
//                     transform: `translateY(${index <= currentSlide ? '0' : '100%'})`,
//                     zIndex: index === currentSlide ? 2 : 1,
//                   }}
//                 >
//                   {SlideComponent}
//                 </div>
//               ))}
//             </div>
//           }
//         />
//         {/* Regular Register page without slides-container class */}
//         <Route path="/register" element={<Register />} />
//       </Routes>
//     </div>
//   );
// }

// export default function AppWrapper() {
//   return (
//     <Router>
//       <App />
//     </Router>
//   );
// }


import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Home from './pages/Home';
import Why from './pages/Why';
import Goals from './pages/Goals';
import Services from './pages/Services';
import Register from './pages/Register';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('your-publishable-stripe-key-here'); // Replace with your actual Stripe publishable key

function App() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [<Goals />, <Home />, <Why />, <Services />, <Goals />];
  const [isThrottled, setIsThrottled] = useState(false);
  const location = useLocation(); // React Router location

  useEffect(() => {
    if (location.pathname !== "/register") {
      const handleScroll = (event) => {
        if (isThrottled) return;

        if (event.deltaY > 0 && currentSlide < slides.length - 1) {
          setCurrentSlide((prevSlide) => prevSlide + 1);
        } else if (event.deltaY < 0 && currentSlide > 0) {
          setCurrentSlide((prevSlide) => prevSlide - 1);
        }

        setIsThrottled(true);
        setTimeout(() => {
          setIsThrottled(false);
        }, 800); // Adjust the delay (in milliseconds) as needed
      };

      window.addEventListener('wheel', handleScroll);

      return () => {
        window.removeEventListener('wheel', handleScroll);
      };
    }
  }, [currentSlide, isThrottled, slides.length, location.pathname]);

  return (
    <div className={`App ${location.pathname === '/register' ? 'no-slide' : ''}`}>
      <Header />
      <Routes>
        {/* Slides section */}
        <Route
          path="/"
          element={
            <div className="slides-container">
              {slides.map((SlideComponent, index) => (
                <div
                  key={index}
                  className={`slide ${index === currentSlide ? 'active' : ''}`}
                  style={{
                    transform: `translateY(${index <= currentSlide ? '0' : '100%'})`,
                    zIndex: index === currentSlide ? 2 : 1,
                  }}
                >
                  {SlideComponent}
                </div>
              ))}
            </div>
          }
        />
        {/* Register page wrapped in the Elements provider for Stripe */}
        <Route
          path="/register"
          element={
            <Elements stripe={stripePromise}>
              <Register />
            </Elements>
          }
        />
      </Routes>
    </div>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
