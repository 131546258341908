import React from 'react';
import rightImage from '../assets/rightImage.jpg'; // Import your image from assets

const Why = () => {
  return (
    <div
      className="w-11/12 mx-auto rounded-t-3xl overflow-hidden relative bg-gray-50 flex justify-between items-center"
      style={{ height: 'calc(100vh - 6rem)' }} // Adjusting height based on the header's height (6rem)
    >
      {/* Left Side Content */}
      <div className="w-full md:w-1/2 p-8">
        <h2 className="text-3xl font-bold text-justify text-gray-900 mb-6 font-poppins">
          We are thrilled to present the third annual Kakatiya Carnival, hosted by the Kakatiya Seva Samithi.
        </h2>
        <p className="text-xl font-roboto text-gray-700 leading-relaxed">
          Anticipate a fantastic turnout from the vibrant Kakatiya community of Dallas and surrounding areas.
        </p>
      </div>

      {/* Right Side Image */}
      <div className="w-full md:w-1/2 h-auto p-8">
        <img src={rightImage} alt="Kakatiya Carnival" className="w-full h-auto object-contain shadow-2xl" />
      </div>
    </div>
  );
};

export default Why;
