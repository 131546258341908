import React, { useState, useEffect } from 'react';
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import culture from '../assets/culture.jpg'; // Replace with the correct image path
import kakathiya from '../assets/Kakathiya.jpg'; // Replace with the correct image path
import joinUs from '../assets/Join US.jpg'; // Replace with the correct image path
import vrDevImage from '../assets/vr-dev.png'; // Replace with the correct image path
import '../styles/services.css';

const servicesData = [
  {
    id: 'cultural',
    title: 'Cultutal Events',
    description: `Immerse yourself in a cultural extravaganza! Savor an authentic Telugu banquet, dive into lively games, and be enchanted by melodious songs and upbeat music. There's so much more to enjoy!`,
    image: culture,
  },
  {
    id: 'Kakatiya Seva Samithi',
    title: 'Kakatiya Seva Samithi',
    description: `Kakatiya Seva Samithi is a non-political, non-profit organization committed to our community's growth. The best part? All event proceeds will support education and hostel facilities for deserving students in the Kamma community.Creating bespoke automation tools for data extraction, transformation, and reporting, enhancing productivity and reducing manual efforts.`,
    image: kakathiya,
  },
  // {
  //   id: 'ai-ml',
  //   title: 'AI and ML',
  //   description: 'Developing custom AI and ML models to integrate advanced functionalities and real-time data processing into various applications.',
  //   image: aiMlImage,
  // },
  {
    id: 'JoinUS',
    title: 'Join us',
    description: `Our passion is to make the Kakatiya Carnival a cherished annual tradition in Dallas. We can't wait to see you and your loved ones there, adding to the event's vibrancy and spirit! 🙏
Join us for a day of fun, culture, and community spirit—you won’t want to miss it!`,
    image: joinUs,
  },
];

const Services = () => {
  const [currentService, setCurrentService] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  // Automatically change service every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentService((prevService) => (prevService + 1) % servicesData.length);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  // Handle fade-in after the service has changed
  useEffect(() => {
    if (!fadeIn) {
      const timer = setTimeout(() => {
        setFadeIn(true);
      }, 500); // Duration to match fade-out and fade-in
      return () => clearTimeout(timer);
    }
  }, [currentService, fadeIn]);

  // Manually go to the previous service
  const handlePrevService = () => {
    if (!fadeIn) return;
    setFadeIn(false);
    setTimeout(() => {
      setCurrentService((prevService) => (prevService - 1 + servicesData.length) % servicesData.length);
    }, 500);
  };

  // Manually go to the next service
  const handleNextService = () => {
    if (!fadeIn) return;
    setFadeIn(false);
    setTimeout(() => {
      setCurrentService((prevService) => (prevService + 1) % servicesData.length);
    }, 500);
  };

  return (
    <div className="w-11/12 mx-auto rounded-t-3xl overflow-hidden relative bg-gray-50 h-screen flex items-center justify-center">
      {/* Service Section */}
      <div
        className={`service-section flex h-full w-full items-center justify-between p-8 transition-opacity duration-500 ease-in-out ${fadeIn ? 'opacity-100' : 'opacity-0'}`}
      >
        <div className="w-1/2 flex flex-col items-start justify-center">
          <h2 className="px-16 text-6xl font-bold text-gray-900 mb-4 font-poppins">{servicesData[currentService].title}</h2>
          <p className="text-xl px-8 text-gray-700 leading-relaxed font-poppins">{servicesData[currentService].description}</p>
        </div>
        <div className="w-1/2 flex items-center justify-center">
          <img src={servicesData[currentService].image} alt={servicesData[currentService].title} className="w-full h-auto object-contain" />
        </div>
      </div>
      {/* Navigation Arrows */}
      <div className="absolute left-0 top-1/2 transform -translate-y-1/2">
        <button onClick={handlePrevService} className="p-4 focus:outline-none">
          <IoIosArrowDropleft className="w-8 h-8 text-gray-900" />
        </button>
      </div>
      <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
        <button onClick={handleNextService} className="p-4 focus:outline-none">
          <IoIosArrowDropright className="w-8 h-8 text-gray-900" />
        </button>
      </div>
    </div>
  );
};

export default Services;
