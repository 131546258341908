import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import event from "../assets/event.jpg";
import '../styles/goals.css';

const Goals = () => {
  const navigate = useNavigate(); // Initialize the navigate hook

  const handleRegisterClick = () => {
    navigate('/register'); // Navigate to the Register page
  };

  return (
    <div
      className="w-11/12 mx-auto rounded-t-3xl overflow-hidden relative bg-gray-50 flex justify-between items-center"
      style={{ height: 'calc(100vh - 6rem)' }} // Adjusting height based on the header's height (6rem)
    >
      {/* Left Side Content */}
      <div className="w-full md:w-1/2 p-8 flex flex-col items-center">
        <h2 className="text-5xl font-bold text-center text-gray-900 mb-6 font-poppins">
          Kakathiya Vanabojanalu
        </h2>
        <p className="text-xl font-roboto text-gray-700 leading-relaxed text-center mb-4">
          Join us for a day of fun, culture, and community spirit—you won’t want to miss it!
        </p>
        {/* Register Button */}
        <button
          onClick={handleRegisterClick} // Add onClick handler for navigation
          className="bg-blue-600 text-white font-bold py-2 px-6 rounded-full hover:bg-blue-700 transition duration-300"
        >
          Register
        </button>
      </div>

      {/* Right Side Image */}
      <div className="w-full md:w-1/2 h-auto p-36">
        <img src={event} alt="Kakatiya Carnival" className="w-auto h-auto object-contain shadow-2xl" />
      </div>
    </div>
  );
};

export default Goals;
